import React from 'react'
import { getCurrentYear } from '../../../utils/commonFunctions'

const RegistrationFooter = () => {
    return (
      <>
<footer className="footer">
  <div className="row copyright">
    <div className="container">
      <p>Copyright @ {getCurrentYear()} GeekSpotters. All rights reserved</p>
    </div>
  </div>
</footer>
<div className="clearfix"></div>
</>

    )
}

export default RegistrationFooter
