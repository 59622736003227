import React, { useContext, useEffect } from 'react'
import { SocketContext } from './SocketProvider';

export default function Socket({on,handle}) {
    const socket = useContext(SocketContext);
    useEffect(() => {
        socket.on(on, handle);
    }, [socket])
    return false;
}
