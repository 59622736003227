import React, { lazy ,Suspense} from "react";
import Splash from "../Splash/index";
import { Switch, Route } from "react-router-dom";
import "../../assets/reg/css/colors/green-style.scss";
import "../../assets/reg/plugins/css/plugins.scss";
import "../../assets/reg/plugins/css/plugins-icon.css";
import "../../assets/dashboard/plugins/css/plugins-icon.css";
import "../../assets/reg/css/style.scss";
import Header from '../common/Header';
function BonusPageLayout({ component: Component, ...rest }) {
  return (
    <>
      
      <div className="main-layout" id="websiteStyles">
      <div className="wrapper">
      <Header />
      <div>
            <Suspense fallback={<Splash />}>
          <Switch>
            <Route {...rest} render={(props) => <Component {...props} />} />
          </Switch>
          </Suspense>
          </div>
       </div>
     </div>
    </>
  );
}

export default BonusPageLayout;
