import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import $ from "jquery";
import "./Header.scss";
import * as constant from "./../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { LogOut } from "../../pages/Signup/Store/action";
import image_path from "./ImagePath";
function Header() {
  const {
    headerLogoImg,
    logogeek_2mobil_b,
    logoImg,
    logo_blanco,
    logogeek_spoilers,
  } = image_path;
  const LoginType = useSelector((state) => state.signup);
  const container = useRef();
  const handleClickOutside = (event) => {
    if (container.current && !container.current.contains(event.target)) {
      setSideToggle(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [handleClickOutside]);
  const { loginType } = LoginType;
  const history = useHistory();
  const dispatch = useDispatch();
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 118) {
      $("#crakeDiv").addClass("is-sticky");
    } else {
      $("#crakeDiv").removeClass("is-sticky");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);
  const [color, setColor] = React.useState("");
  const [mobilescreen, setmobilescreen] = useState(false);
  const [geekclassName, setgeekclassName] = useState("");
  const [headerLogo, setheaderLogo] = useState("");
  const [headerlogomobil, setheaderlogomobil] = useState("");
  const [meanmenugeekclassName, setmeanmenugeekclassName] = useState("");
  const [whiteheader, setwhiteheader] = useState("");
  const [navtogglt, setNavToggle] = useState(false);
  const [sideToggle, setSideToggle] = useState(false);
  const location = useLocation();
  const styles = {
    color: color,
  };
  const handleSideToggle = () => {
    setSideToggle(!sideToggle);
  };

  useEffect(() => {
    if (location.pathname === "/iamgeek") {
      setgeekclassName("geeks");
      setheaderLogo(logogeek_spoilers);
      setheaderlogomobil(logoImg);
      setmeanmenugeekclassName("meanmenugeek");
      setwhiteheader("whiteheader");
      $("#crakeDiv").addClass("marketing-navbar");
    } else if (location.pathname === "/becomespotter") {
      setgeekclassName("geek");
      setheaderLogo(logo_blanco);
      setheaderlogomobil(logogeek_2mobil_b);
      setmeanmenugeekclassName("meanmenugeekb");
      setwhiteheader("blueheader");

      $("#crakeDiv").removeClass("marketing-navbar");
    } else {
      setgeekclassName("geek");
      setheaderLogo(headerLogoImg);
      setheaderlogomobil(logoImg);
      setmeanmenugeekclassName("meanmenugeek");
      setwhiteheader("whiteheader");
      $("#crakeDiv").addClass("marketing-navbar");
    }
  }, [
    location.pathname,
    logogeek_spoilers,
    logoImg,
    logo_blanco,
    logogeek_2mobil_b,
    headerLogoImg,
  ]);
  const closeSideBar = () => {
    setNavToggle(false);
  };
  let colors;

  if (location.pathname === "/becomespotter") {
    colors = "#46a5cc";
  } else {
    colors = "#fff";
  }

  let imgcol;
  if (window.innerWidth <= 980) {
    imgcol = "block";
  } else {
    imgcol = "none";
  }
  useEffect(() => {
    window.onresize = (e) => {
      if (window.innerWidth <= 991) {
        setmobilescreen(true);
        $(".crake-mobile-nav").css("display", "block");
      } else {
        $(".crake-mobile-nav").css("display", "none");

        setmobilescreen(false);
      }
    };
    if (window.innerWidth <= 991) {
      $(".crake-mobile-navs").css("display", "block");
      setmobilescreen(true);
    } else {
      $(".crake-mobile-navs").css("display", "none");
      setmobilescreen(false);
    }
  }, []);
  const GoToLogin = () => {
    history.push("/login");
  };
  const GoToSignUp = () => {
    history.push("/signup");
  };
  const Faq = () => {
    history.push("/faq");
  };
  const goToMyAccount = () => {
    console.log("clieckkkkk")
    if (loginType === "candidate" || localStorage.loginType === "candidate") {
      history.push("/referrer/dashboard");
    }
    if (loginType === "employer" || localStorage.loginType === "employer") {
      history.push("/dashboard/emp");
    }
    if (loginType === "admin" || localStorage.loginType === "admin") {
      history.push("/dashboard/super");
    }
    if (loginType === "recruiter" || localStorage.loginType === "recruiter") {
      history.push("/dashboard/spotter");
    }
  };
  const logOut = () => {
    dispatch(LogOut());
    history.push("/");
  };
  console.log(localStorage.token, "localStorage.token");
  return (
    <>
      <header id="header" className={whiteheader}>
        <div
          className={
            location.pathname === "/becomespotter" ? "header-2-3" : "header-2"
          }
        >
          <div
            className={`navbar-header marketing-mobile-nav   ${meanmenugeekclassName}`}
            ref={container}
            style={{ background: colors }}
          >
            {location.pathname === "/becomespotter" ? (
              <div
                className="logosnew"
                style={{ display: imgcol, width: "150px" }}
              >
                <Link to="/">
                  <img src={headerlogomobil} alt="logo" />
                </Link>
              </div>
            ) : (
              <div className="logos">
                <Link to="/">
                  <img src={headerlogomobil} alt="logo" />
                </Link>
              </div>
            )}

            {location.pathname === "/becomespotter" ? (
              <a
                type="button"
                className=" navbar-toggle nv-toggle-btn-img-geek"
                aria-expanded={sideToggle ? "true" : "false"}
                onClick={handleSideToggle}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </a>
            ) : (
              <a
                type="button"
                className=" navbar-toggle nv-toggle-btn"
                aria-expanded={sideToggle ? "true" : "false"}
                onClick={handleSideToggle}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </a>
            )}
          </div>
          <div className="navbar-default sidebar " role="navigation">
            <div
              className={
                sideToggle
                  ? "sidebar-nav navbar-collapse collapse show"
                  : "sidebar-nav navbar-collapse collapse "
              }
            >
              <ul className="navbar-nav nav ml-auto">
                {constant.headerList.map((item) => (
                  <li
                    className={
                      item.path.includes(window.location.pathname)
                        ? "active nav-item"
                        : "nav-item"
                    }
                    onClick={closeSideBar}
                    key={item.path}
                    style={{display: item.path === "/myaccount" ? "none" : "block"}}
                  >
                    <Link
                      to={item.path}
                      toactive="active"
                      //  className="nav-link"
                      className={
                        item.path.includes(window.location.pathname)
                          ? "active nav-link"
                          : "nav-link"
                      }
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}

                {mobilescreen === false && localStorage.token === undefined ? (
                  <>
                    <button
                      className="nav-item"
                      style={{
                        background: "#ffffff",
                        borderRadius: "7px",
                        margin: " 9px 0px",
                        border: "1px solid #000000",
                      }}
                    >
                      <Link
                        style={styles}
                        onMouseEnter={() => setColor("#24b474")}
                        onMouseLeave={() => setColor("#000000")}
                        to="/signup"
                      >
                        Login or Sign Up
                      </Link>
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {mobilescreen && localStorage.token === undefined ? (
                  <>
                    <li className="nav-item" onClick={closeSideBar}>
                      <Link
                        to="/login"
                        toactive="active"
                        className={
                          location.pathname === "/login"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        Login
                      </Link>
                    </li>
                    <li className="nav-item" onClick={closeSideBar}>
                      <Link
                        to="/signup"
                        toactive="active"
                        // className="nav-link"
                        className={
                          location.pathname === "/signup"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        Sign Up
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {mobilescreen && localStorage.token ? (
                  <>
                    <li className="nav-item">
                      <Link to="#" onClick={goToMyAccount} className="nav-link">
                        My Account
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="#" onClick={logOut} className="nav-link">
                        Logout
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {mobilescreen === true ? (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/becomespotter"
                        toactive="active"
                        // className="nav-link"
                        className={
                          location.pathname === "/becomespotter"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        Become a Spotter
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/about"
                        toactive="active"
                        // className="nav-link"
                        className={
                          location.pathname === "/about"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        About us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/contactus"
                        toactive="active"
                        //className="nav-link"
                        className={
                          location.pathname === "/contactus"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        Contact
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* start mob nav */}

        {/* Mob mob nav */}
        <div
          className={
            location.pathname === "/becomespotter"
              ? "iamgeek-color crake-nav marketing-navbar"
              : "crake-nav marketing-navbar"
          }
          id="crakeDiv"
        >
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <div className={geekclassName}>
                <Link className="navbar-brand" to="/">
                  <img src={headerLogo} alt="logo" />
                </Link>
              </div>
              <div className="collapse navbar-collapse mean-menu">
                <ul className="navbar-nav nav ml-auto">
                  {constant.headerList.map((item) => (
                    <li
                      className={
                        item.path === window.location.pathname
                          ? "active nav-item"
                          : "nav-item"
                      }
                      onClick={closeSideBar}
                      key={item.path}
                      
                    >
                      <Link
                        to={item.path === "/myaccount" ? "#" : item.path}
                        toactive="active"
                        //  className="nav-link"
                        style={{marginRight:item.name === "Find Jobs" ? "0px" : "10px"}}
                        className={
                          item.path === window.location.pathname
                            ? "active nav-link"
                            : "nav-link"
                        }
                        onClick={
                          item.name === "My Account" ? goToMyAccount : null
                        }
                      >
                        {item.name === "My Account" ? (
                          localStorage.token ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                gap: "5px",
                              }}
                            >
                              <i className="ti-user"></i>My Account
                            </div>
                          ) : null
                        ) : item.name === "Find Jobs" ? (
                          <div
                            style={{
                              borderRight:
                                localStorage.token && "1px solid #c6c6c6",
                              paddingRight: localStorage.token ? "15px" : "0px",
                            }}
                          >
                            {item.name}
                          </div>
                        ) : (
                          item.name
                        )}
                      </Link>
                    </li>
                  ))}
                  {/* <li className="nav-item">
                    <Link
                      to="/hiretalent"
                      toactive="active"
                      className="nav-link"
                    >
                      Hire Talent
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/iamgeek"
                      routerLinkActive="active"
                      className="nav-link"
                    >
                      I’m a Geek
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/jobs"
                      routerLinkActive="active"
                      className="nav-link"
                    >
                      Find Jobs
                    </Link>
                  </li> */}
                  {mobilescreen === false &&
                  localStorage.token === undefined ? (
                    <>
                      <button
                        className="nav-item"
                        style={{
                          background: "#ffffff",
                          borderRadius: "7px",
                          margin: " 9px 0px",
                          border: "1px solid #000000",
                        }}
                      >
                        <Link
                          style={styles}
                          onMouseEnter={() => setColor("#24b474")}
                          onMouseLeave={() => setColor("#000000")}
                          to="/signup"
                        >
                          Login or Sign Up
                        </Link>
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  {mobilescreen && localStorage.token === undefined ? (
                    <>
                      <li className="nav-item">
                        <Link
                          to="/login"
                          toactive="active"
                          // className="nav-link"
                          className={
                            window.location.pathname === "/login"
                              ? "active nav-link"
                              : "nav-link"
                          }
                        >
                          Login
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/signup"
                          toactive="active"
                          className="nav-link"
                        >
                          Sign Up
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {mobilescreen && localStorage.token ? (
                    <>
                      <li className="nav-item">
                        <Link
                          to="#"
                          onClick={goToMyAccount}
                          className="nav-link"
                        >
                          My Account
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="#" onClick={logOut} className="nav-link">
                          Logout
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {mobilescreen === true ? (
                    <>
                      <li className="nav-item">
                        <Link
                          to="/becomespotter"
                          toactive="active"
                          className="nav-link"
                        >
                          Become a Spotter
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/about"
                          toactive="active"
                          className="nav-link"
                        >
                          About us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/contactus"
                          toactive="active"
                          className="nav-link"
                        >
                          Contact
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
              {localStorage.token === undefined && <div className="mr-auto others-option">
                <ul className="navbar-nav">
                  <li data-toggle="modal" data-target="#myModal2">
                    <div className="side-menu">
                      <span className="bar-1"></span>
                      <span className="bar-2"></span>
                      <span className="bar-3"></span>
                    </div>
                  </li>
                </ul>
              </div>}
            </nav>
          </div>
        </div>
      </header>

      <div className="sidebar-modal">
        <div
          id="myModal2"
          className="modal right fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel2"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <em className="icofont-close"></em>
                  </span>
                </button>
                <h2 className="modal-title" id="myModalLabel2">
                  <Link to="/">
                    <img src={logo_blanco} alt="logo" />
                  </Link>
                </h2>
              </div>
              <div className="modal-body">
                <div className="sidebar-modal-widget">
                  <h3 className="title">Additional Links</h3>
                  <ul>
                    {localStorage.token ? (
                      <>
                        <li>
                          <Link
                            to="#"
                            onClick={goToMyAccount}
                            data-dismiss="modal"
                          >
                            My Account
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={Faq} data-dismiss="modal">
                            FAQ
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={logOut} data-dismiss="modal">
                            Logout
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link
                            to="#"
                            onClick={GoToLogin}
                            className={
                              window.location.pathname === "/login"
                                ? "nav-color"
                                : ""
                            }
                            data-dismiss="modal"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            onClick={GoToSignUp}
                            data-dismiss="modal"
                            className={
                              window.location.pathname === "/signup"
                                ? "nav-color"
                                : ""
                            }
                          >
                            Sign Up
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            onClick={Faq}
                            data-dismiss="modal"
                            className={
                              window.location.pathname === "/faq"
                                ? "nav-color"
                                : ""
                            }
                          >
                            FAQ
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <div className="sidebar-modal-widget">
                  <h3 className="title">Contact Info</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="icofont-google-map"></i> Address
                      <span>Sunny Isles Beach, Florida</span>
                    </li>
                    <li>
                      <i className="icofont-email"></i> Email
                      <span>recruiting@geekspotters.com</span>
                    </li>
                    <li>
                      <i className="icofont-phone"></i> Phone
                      <span>833.425.2900</span>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-modal-widget">
                  <h3 className="title">Connect With Us</h3>
                  <ul className="social-list">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/geekspotters/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/GeekSpotters/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
