import React,{lazy ,Suspense} from "react";
import Home from '../../pages/Home/Home'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Splash from "../Splash/index";
import '../../assets/css/meanmenu.css'
import '../../assets/css/responsive.scss'
import '../../assets/css/index.scss'
import '../../assets/css/owl.carousel.min.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/style-website.scss'
import '../../assets/css/icofont.min.css'
import "../../assets/dashboard/plugins/css/plugins-icon.css";
import { homeRoutes } from "../../utils/routes";
import {
  Redirect,
  Switch,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import './WebsiteLayout.scss';

const WebsiteLayout = (props, ...rest) => {
  const { match } = props;
 
  return (
      <>
      <div className="main-layout" id="websiteStyles">
        <Header />
        <div>
        <Suspense fallback={<Splash />}>
          <Switch>
            {homeRoutes.map((item) => (
              <Route
                {...rest}
                key={item.path}
                render={(props) => <item.component {...props} />}
                path={item.path}
              />
            ))}
          </Switch>
        <Footer />
        </Suspense>
        </div>
      </div>
    </>
  );
};

export default WebsiteLayout;
