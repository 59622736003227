import React, { Suspense } from "react";
import Splash from "../Splash/index";
import { Switch, Route, Link } from "react-router-dom";
import "../../assets/reg/css/colors/green-style.scss";
import "../../assets/reg/plugins/css/plugins.scss";
import "../../assets/reg/plugins/css/plugins-icon.css";
import "../../assets/dashboard/plugins/css/plugins-icon.css";
import "../../assets/reg/css/style.scss";
import image_path from "../common/ImagePath";

const { headerLogoImg } = image_path;

function CompanyJobLayout({ component: Component, ...rest }) {
  return (
    <>
      <Suspense fallback={<Splash />}>
        <div className="main-layout browse-company-jobs" id="websiteStyles">
          <div className="wrapper">
            <div>
              <Switch>
                <Route {...rest} render={(props) => <Component {...props} />} />
              </Switch>
            </div>
            <footer className="footer">
              <div className="copyright">
                <div className="container">
                  <p>
                    Jobs powered by{" "}
                    <Link className="navbar-brand" to="/">
                      <img src={headerLogoImg} alt="logo" />
                    </Link>
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default CompanyJobLayout;
