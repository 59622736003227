import React, { Suspense, useEffect } from "react";
import Splash from "../Splash/index";
import {
  Redirect,
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import Header from "../../pages/Dashboard/DashboardHeader/DashboardHeader";
import Footer from "../../pages/Dashboard/DashboardFooter/DashboardFooter";
import "../../assets/dashboard/plugins/css/plugins.scss";
import "../../assets/dashboard/plugins/css/plugins-icon.css";
import "../../assets/dashboard/css/style.scss";
import "../../assets/css/icofont.min.css";
import { useLocation, useHistory } from "react-router-dom";
import { dashboardRoutes } from "../../utils/routes";
import * as session from "../../utils/session";
import { setRegistrationType, setUserId } from "../../pages/Signup/Store/action";
import { useDispatch } from "react-redux";
import * as constant from "../../utils/constant";
import SocketProvider from "../socket/SocketProvider";
import './DashboardLayout.scss';

function DashboardLayout(props, ...rest) {
  const location = useLocation();
  const [showSideMenu, setShowSideMenu] = React.useState(true);
  
  React.useEffect(() => {
    checkUser();
  });
  const dispatch = useDispatch();
  const history = useHistory();
  
  const userType = constant.userType;

  const checkUser = () => {

    let type = session.getUserType();
    let id =  session.getCompanyId();
    if(type === userType.employer ) {
    
      if(id === "undefined" || id === undefined || id === null) {
      
        return AsEmployer();
      }else{
      
        return true;
      }
    }else{
     
      return true;
    }
  }

  const AsEmployer = () => {
    let userId = localStorage.userId;
   
    dispatch(setUserId(userId));
    dispatch(setRegistrationType("employer"));
    history.push("/registration/setcompany");
  };
const collapseMenu = () => {
    if (showSideMenu === true) {
      setShowSideMenu(false);
    } else {
      setShowSideMenu(true);
    }
  };
  return (
      <>
     
    {localStorage.token  ? (
      <SocketProvider>
        <div className="main-layout" id="dashboardStyles">
          <div id="wrapper" className={showSideMenu ? '' : 'active'}>
            <Header  collapseMenu={collapseMenu} showSideMenu={showSideMenu} />
            <Suspense  fallback={<Splash />}>
              <Switch>
                {dashboardRoutes.map((item) => (
                  <Route
                    {...rest}
                    key={item.path}
                    render={(props) => <item.component {...props} />}
                    path={item.path}
                  />
                ))}
              </Switch>
            </Suspense>
            <Footer />
          </div>
        </div>
      </SocketProvider>
    ) : (
        <Redirect to={{ pathname: '/login' }} />
      )}
      </>
  );
}

export default DashboardLayout;
