import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import Header from "../../pages/Registration/RegistrationHearder/RegistrationHeader";
import Footer from "../../pages/Registration/RegistrationFooter/RegistrationFooter";
import { useLocation } from "react-router-dom";
// import CreateAccount from "../../pages/Registration/CreateAccount/CreateAccount";
import "../../assets/reg/css/colors/green-style.scss";
import "../../assets/reg/plugins/css/plugins.scss";
import "../../assets/reg/plugins/css/plugins-icon.css";
import "../../assets/dashboard/plugins/css/plugins-icon.css";
import "../../assets/reg/css/style.scss";
import { registrationRoutes } from "../../utils/routes";
function ResetPasswordLayout({ component: Component, ...rest }) {
  return (
    <>
      <div className="main-layout" id="registrationStyles">
        <div className="wrapper">
         
          <Switch>
            <Route {...rest} render={(props) => <Component {...props} />} />
          </Switch>
       
        </div>
      </div>
    </>
  );
}

export default ResetPasswordLayout;
