import React, { createRef } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import * as session from "../../utils/session";
import * as service from "../../utils/service";
import { useEffect } from "react";

export default function Notification({ item, setIsLoading, scroll, lastId, setNotifications }) {
  const listRef = createRef();

  useEffect(() => {
    if (scroll) {
      listRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [scroll]);

  const removeSingleNotification = (id) => {
    setIsLoading(true);
    service
      .closeSingleNotification(id)
      .then((resp) => {
        if (resp.data.status) {
          setIsLoading(false);
          if(item){
            setNotifications(prevState=>{
                return prevState.filter((prev) => prev?._id !== item?._id)
            })
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  return (
    <>
    <li key={item._id} ref={listRef} className={item?.recipients[0]?.seen?`task-detail`:`task-detail unseen-notification`}>
      <div className="d-flex">
        <div className="messages">{item?.notification}</div>
        <div className="remove-noti">
          <span
            onClick={() => removeSingleNotification(item._id)}
            className="btn p-0 "
            data-toggle="tooltip"
            data-placement="top"
            title="Remove"
          >
            <i className="ti-close"></i>
          </span>
        </div>
      </div>
      <div className="job-buttons">
        <span className="task-time">
          <Moment fromNow date={item?.createdAt} />
        </span>
      </div>
    {lastId !== item._id && (
      <hr className="hr-notifications" />
    )}
    </li>
    </>
  );
}
