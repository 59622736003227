const initialState = {
  regType: null,
  userId:null,
  jobReview: null,
  companyDetails:null,
  loginType:null
};

const signUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTRATION_TYPE":
      return {
        ...state,
        regType: action.data,
      };
      case "USER_ID":
        localStorage.setItem("userId",action.data)
      return {
        ...state,
        userId: action.data,
      };
      case "LOGIN_TYPE":
        localStorage.setItem("loginType",action.data)
      return {
        ...state,
        loginType: action.data,
      };
    case "JOB_DETAILS":
      return {
        ...state,
        jobReview: action.data,
      };
    case "COMPANY_DETAILS":
      return {
        ...state,
        companyDetails: action.data,
      };
      case "LOGIN_USER":
        localStorage.setItem("token",action.data)
        return {
          ...state,
        };
        case "LOGOUT_USER":
        localStorage.clear()
        return {
          ...state,
          regType:null
        };
    default:
      return state;
  }
};

export default signUpReducer;
