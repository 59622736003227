const join = require("url-join");
const { handleExpireTokenNotification } = require("../utils/handleExpireTokenNotification");
const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
exports.setupAxios = (axios, store) => {
  axios.interceptors.request.use((config) => {
    // ****** from - redux ******
    // const token = store.getState().[stateName].token;
    //********* from localStorage *********
    const token = localStorage.getItem("token")
      ? `Bearer ${localStorage.getItem("token")}`
      : null;
    config.headers.Authorization = token;
    // Concatenate base path if not an absolute URL
    if (!isAbsoluteURLRegex.test(config.url)) {
      let apiUrl =
        process.env.React_App_NODE_ENV === "dev"
          ? process.env.React_App_API
          : process.env.React_App_Prod_API;

      config.url = join(`${apiUrl}`, config.url);
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log("error---", error);
      console.log("err", error.response.data.message);
      if (error.response.status === 401) {
        if (
          error.response.data.message ==
            "Invalid token. Please log in again!" ||
          error.response.data.message ==
            "Token has expired. Please log in again!"
        ) {
          handleExpireTokenNotification(
            error.response.status,
            error.response.data.message
          );
          localStorage.clear();
          window.location.href = "/login";
        }
      }
      return Promise.reject(error);
    }
  );
};
