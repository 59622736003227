import React from 'react'

function index() {
    return (
        <div className="preloader-area">
        <div className="lds-hourglass"></div>
      </div>
    )
}

export default index
