import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { getCurrentYear } from "../../utils/commonFunctions";
function Footer() {
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 800) {
      $(".back-to-top").addClass("show-back-to-top");
    } else {
      $(".back-to-top").removeClass("show-back-to-top");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="footer-area ptb-100 pb-0 bg-image footercolor">
        <div className="container sub-footer">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <Link to="/" className="logo">
                  <img
                    src={require("../../assets/Image/png/logogeek.png").default}
                    alt="logo2"
                  />
                </Link>
                <p>
                  We specialize in recruiting for growing tech startups. 
                  Our recruiters have extensive in-house and agency experience 
                  in Silicon Valley and have worked for big tech companies, as 
                  well as emerging startups.
                </p>
                <ul className="social-list">
                  <li>
                    <a
                      href="https://www.linkedin.com/company/geekspotters/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/GeekSpotters/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget pl-5"></div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <ul className="list">
                  <li>
                    <Link to="/hiretalent">Hire Talent</Link>
                  </li>
                  <li>
                    <Link to="/iamgeek">I’m a Geek</Link>
                  </li>
                  <li>
                    <Link to="/becomespotter">Become a Spotter</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <ul className="list">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contactus">Contact</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <p>Copyright @ {getCurrentYear()} GeekSpotters. All rights reserved</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <ul>
                  <li>
                    <Link to="/terms">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="back-to-top " onClick={scrollTop}>
        Top
      </div>
    </>
  );
}

export default Footer;
