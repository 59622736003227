import axios from "axios";

export const createCandidateWithResume = (data) => {
  return axios.post("/candidate/by-resume", data).then((resp) => {
    return resp;
  });
};
export const createCandidate = (data) => {
  return axios.post("/candidate", data).then((resp) => {
    return resp;
  });
};
export const getCandidate = (status, search, type) => {
  return axios
    .get(`/candidate?status=${status}&search=${search}&type=${type}`)
    .then((resp) => {
      return resp;
    });
};

export const searchCandidate = (search) => {
  return axios.get(`/candidate/list?search=${search}`).then((resp) => {
    return resp;
  });
};

export const saveUserInterviewNotes = (candidateId, notes, jobId) => {
  return axios
    .post(`/candidateNote/${candidateId}`, {
      note: notes,
      job: jobId,
    })
    .then((resp) => {
      return resp;
    });
};

export const getOnGoingChats = (candidateId, companyId) => {
  return axios
    .get(`/employerCandidateNote/getByRoom/${candidateId}?company=${companyId}`)
    .then((resp) => {
      return resp;
    });
};

export const addToRoom = (roomId, data) => {
  return axios
    .put(`/employerCandidateNote/addToRoom/${roomId}`, data)
    .then((resp) => {
      return resp;
    });
};
// /employerCandidateNote/addToRoom/62dbcd92328316493e45d517
export const getCandidateInterviewNotes = (candidateId) => {
  return axios.get(`/candidateNote/${candidateId}`).then((resp) => {
    return resp;
  });
};

export const getAllCandidateNote = (jobId) => {
  return axios.get(`/candidateNote/getByJob/${jobId}`).then((resp) => {
    return resp;
  });
};

export const deleteCandidateInterviewNotes = (noteId) => {
  return axios.delete(`/candidateNote/${noteId}`).then((resp) => {
    return resp;
  });
};

export const getCandidateById = (id) => {
  return axios.get(`/candidate/` + id).then((resp) => {
    return resp;
  });
};

export const updateCandidateByID = (data, id) => {
  return axios.put(`candidate/${id}`, data).then((res) => {
    return res;
  });
};

export const createTeam = (data) => {
  return axios.post("/employer/team-member", data).then((res) => {
    return res;
  });
};
export const EditTeam = (data, _id) => {
  return axios.put(`/employer/team-member/${_id}`, data).then((res) => {
    return res;
  });
};
export const deleteTeamMember = (userId) => {
  return axios.delete(`/employer/delete-team-member/${userId}`).then((resp) => {
    return resp;
  });
};

export const updateStatus = (candidateId, status, data) => {
  return axios
    .put(`/job/candidate/${candidateId}/status/${status}`, data)
    .then((res) => {
      return res;
    });
};

export const addEmployerNote = (candidateId, data) => {
  return axios
    .post(`/employerCandidateNote/${candidateId}`, data)
    .then((res) => {
      return res;
    });
};
export const getEmployerNote = (candidate, company, job) => {
  return axios
    .get(`/employerCandidateNote/${candidate}?company=${company}&job=${job}`)
    .then((res) => {
      return res;
    });
};

export const getJobList = (
  status,
  search,
  page,
  limit,
  selectedEmployerIds
) => {
  return axios
    .post(
      `/job/getJobList?status=${status}&search=${search}&page=${page}&limit=${limit}`,
      { companyId: selectedEmployerIds }
    )
    .then((resp) => {
      return resp;
    });
};

export const getCategoryList = (mainCategoryId) => {
  return axios.get(`/category/${mainCategoryId}`).then((resp) => {
    return resp;
  });
};
export const getMainCategoryList = () => {
  return axios.get(`/mainCategory`).then((resp) => {
    return resp;
  });
};

export const getCanidateByCategory = (categoryId) => {
  return axios.get(`/candidate/getByCategory/${categoryId}`).then((resp) => {
    return resp;
  });
};

export const getInterviewList = (search) => {
  return axios.get(`/interview?search=${search}`).then((resp) => {
    return resp;
  });
};
export const getInterviewListById = (id) => {
  return axios.get(`/interview/${id}`).then((resp) => {
    return resp;
  });
};
export const CondidateList = () => {
  return axios.get("/candidate?status= ").then((res) => {
    return res;
  });
};

export const viewJob = (jobid, canid) => {
  return axios.post(`/job/${jobid}/apply/${canid}`).then((res) => {
    return res;
  });
};

export const ChangeStatus = (candidateid, status) => {
  return axios
    .put(
      `/job/candidate/${candidateid}/status/${status}`,
      { interviewDate: 12 },
      {
        headers: {
          authorizatin: localStorage.token,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const sendCandidateMail = (data) => {
  return axios.post("/email/send-candidate", data).then((res) => {
    return res;
  });
};

export const getDashboard = () => {
  return axios.get("/dashboard").then((resp) => {
    return resp;
  });
};

export const uploadCandidateWithResume = (data, candidateId) => {
  return axios.put(`/candidate/by-resume/${candidateId}`, data).then((resp) => {
    return resp;
  });
};

export const candidateChangePassword = (data) => {
  return axios.post("/auth/change-password", data).then((resp) => {
    return resp;
  });
};

export const editSpotterProfile = (data) => {
  return axios.put("/recruiter/edit/", data).then((resp) => {
    return resp;
  });
};
export const employerList = (page, limit, search) => {
  return axios
    .get(`/employer/list/?page=${page}&limit=${limit}&search=${search}`)
    .then((resp) => {
      return resp;
    });
};

export const createEmployer = (data) => {
  return axios.post(`/employer/create-employer`, data).then((resp) => {
    return resp;
  });
};

export const serchRecruiters = (status, search, page, limit) => {
  return axios
    .get(
      `/recruiter?status=${status}&search=${search}&page=${page}&limit=${limit}`
    )
    .then((resp) => {
      return resp;
    });
};

export const getEmployeeById = (id) => {
  return axios.get(`/employer/get-employer/${id}`).then((resp) => {
    return resp;
  });
};
export const updateEmployById = (id, data) => {
  return axios.put(`/employer/update-employer/${id}`, data).then((resp) => {
    return resp;
  });
};
export const deleteRecruiter = (userId) => {
  return axios.delete(`/recruiter/${userId}`).then((resp) => {
    return resp;
  });
};

// export const searchEmployee =(search="") =>{
//   return axios.get(`/employer/list?page={page}&limit={limit}&search=${search}`).then(resp=>{
//     return resp
//   })
// }

export const sentNotes = (id, data) => {
  return axios.post(`/note/candidate/${id}`, data).then((resp) => {
    return resp;
  });
};
export const getBrowseJobList = (data, page, limit) => {
  return axios.post(`/job/getJobs`, data).then((resp) => {
    return resp;
  });
};

export const getJobowner = (companyId) => {
  return axios
    .get(`/employer/employer-team-member/${companyId}`)
    .then((resp) => {
      return resp;
    });
};
export const getNoteByRecNAdmin = (candidate, companyId) => {
  return axios
    .get(`/employerCandidateNote/getByCandidate/${candidate}?company=${companyId}`)
    .then((resp) => {
      return resp;
    });
};
export const deleteNote = (employerCandidateNoteId) => {
  return axios
    .delete(`/employerCandidateNote/${employerCandidateNoteId}`)
    .then((resp) => {
      return resp;
    });
};

export const createReffer = (data) => {
  return axios.post("/candidate/referFromDashboard", data).then((resp) => {
    return resp;
  });
};

export const checkEmail = (email) => {
  return axios
    .get(decodeURI(`/auth/checkEmail?email=${email}`))
    .then((resp) => {
      return resp;
    });
};

export const checkCandidateEmailExistance = (email) => {
  return axios.get(`/candidate/checkEmail?email=${email}`).then((resp) => {
    return resp;
  });
};

export const transferCandidateOwnerShip = (candidate_id) => {
  const data = {
    addedBy: localStorage.getItem("userId"),
  };

  return axios
    .put(`/candidate/transferOwnership/${candidate_id}`, data)
    .then((resp) => {
      return resp;
    });
};

export const getJobByID = (id) => {
  return axios.get(`/job/${id}`).then((resp) => {
    return resp;
  });
};
export const askYourQuestion = (data) => {
  return axios.post("/email/contact-us", data).then((resp) => {
    return resp;
  });
};
export const getEmployerList = () => {
  return axios.get("/employer/getEmployers").then((resp) => {
    return resp;
  });
};
export const loginGeek = (data) => {
  return axios.post("/auth/login", data).then((resp) => {
    return resp;
  });
};
export const signupGeek = (data) => {
  return axios.post("/auth/signup", data).then((resp) => {
    return resp;
  });
};
export const candidateRefer = (data, userId) => {
  return axios.post(`/candidate//refer/${userId}`, data).then((resp) => {
    return resp;
  });
};

export const updateRefererMessage = (data, candidateId) => {
  return axios
    .put(`/candidate/updateRefer/${candidateId}`, data)
    .then((resp) => {
      return resp;
    });
};
export const getCandidateByMail = (email) => {
  return axios.get(`/candidate/getByEmail/${email}`).then((resp) => {
    return resp;
  });
};

export const referalConfirmation = (data, email) => {
  return axios
    .put(`/candidate/referralConfirmation/${email}`, data)
    .then((resp) => {
      return resp;
    });
};
export const DeleteJod = (data, id) => {
  return axios.put(`/job/delete/${id}`, data).then((resp) => {
    return resp;
  });
};
export const profileTeamMember = (data, userId) => {
  return axios
    .put(`/employer/complete-profile/${userId}`, data)
    .then((resp) => {
      return resp;
    });
};
export const confirmEmail = (token) => {
  return axios.post(`/auth/confirm-identifier/${token}`).then((resp) => {
    return resp;
  });
};
export const confirmEmployerEmail = (userId, bodyFormData) => {
  return axios
    .put(`/employer/complete-employer-profile/${userId}`, bodyFormData)
    .then((resp) => {
      return resp;
    });
};

export const forgetPassword = (email) => {
  return axios.post(`/auth/forgot-password/${email}`).then((resp) => {
    return resp;
  });
};
export const confirmUserId = (token) => {
  return axios.post(`/auth/confirm-identifier/${token}`).then((resp) => {
    return resp;
  });
};
export const resetPassword = (id, data) => {
  return axios.put(`/auth/reset-password/${id}`, data).then((resp) => {
    return resp;
  });
};
export const createInterview = (data, companyId) => {
  return axios.post(`/interviewer/${companyId}`, data).then((resp) => {
    return resp;
  });
};

export const getInterview = (companyId) => {
  return axios.get(`/interviewer/${companyId}`).then((resp) => {
    return resp;
  });
};
export const deleteInterview = (_id, companyId) => {
  return axios
    .delete(`/interviewer/${companyId}/delete/${_id}`)
    .then((resp) => {
      return resp;
    });
};

export const leaveTeamMember = (userId, data) => {
  return axios
    .put(`/employer/leave-team-member/${userId}`, data)
    .then((resp) => {
      return resp;
    });
};

export const notifyInterviewTeam = (candidateId, companyId, data) => {
  return axios
    .post(`/email/notify-interview-team/${candidateId}/${companyId}`, data)
    .then((resp) => {
      return resp;
    });
};

export const bugReport = (data) => {
  return axios.post("/email/bug-report", data).then((resp) => {
    return resp;
  });
};

export const waitTimeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const checkLinkedInEmail = (data) => {
  return axios.post(`/auth/check-linkedIn`, data).then((resp) => {
    return resp;
  });
};
export const signupSocial = (data) => {
  return axios.post(`/auth/signup-social`, data).then((resp) => {
    return resp;
  });
};

export const getNotification = (page, limit) => {
  return axios.get(`/notification?page=${page}&limit=${limit}`).then((resp) => {
    return resp;
  });
};

export const closeAllNotification = () => {
  return axios.post(`/notification/hide`).then((resp) => {
    return resp;
  });
};
export const closeSingleNotification = (notificationId) => {
  return axios.post(`/notification/hide?nId=${notificationId}`).then((resp) => {
    return resp;
  });
};
export const createBonus = (data) => {
  return axios.post("/bonus", data).then((resp) => {
    return resp;
  });
};
export const verifyBonousOtp = (data) => {
  return axios.post("/bonus/verify-otp", data).then((resp) => {
    return resp;
  });
};
export const updateBonus = (contactNumber, data) => {
  return axios.put(`/bonus/${contactNumber}`, data).then((resp) => {
    return resp;
  });
};

export const support = (data) => {
  return axios.post("/email/support", data).then((resp) => {
    return resp;
  });
};

export const getUserById = (id) => {
  return axios.get(`/auth/` + id).then((resp) => {
    return resp;
  });
};

export const aimiPostForm = (data) => {
  return axios.post("/aimi", data).then((resp) => {
    return resp;
  });
};

export const getJobsByCompanyName = (data, page, limit) => {
  return axios
    .post(`/job/getJobsByCompanyName?page=${page}&limit=${limit}`, data)
    .then((resp) => {
      return resp;
    });
};
