export const formatSalary = (x) => {
    if(!x){
        return x;
    }
    return '$'+ x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const capitalize = (x) => {
    if(!x){
        return x;
    }
    return x.charAt(0).toUpperCase() + x.slice(1);
}

export const formatJobType = (x) => {
    if(!x){
        return x;
    }
    if(x ==='part-time') {
        return 'Part Time'
    }else if(x ==='full-time') {
        return 'Full Time'
    }
    return "Freelancer";
}

export const formatJobLocation = (x) => {
    if(!x){
        return x;
    }
    if(x ==='remote') {
        return 'Remote'
    }else if(x ==='hybrid') {
        return 'Hybrid'
    }
    return "In Office";
}

export const formatStatus = (x) => {
    if(!x){
        return x;
    }
    if(x === 'awaiting-approval'){
        return 'Waiting For Approval';
    }
    return x;
}

export const formatSkills = (x) => {
    if(!x){
        return x;
    }
    return x.map((item, i)=>{
        if(i === x.length-1){
            return item;
        }else{
            return item+', ';
        }

    })
}

export const formateSalery=(sal)=>{
    if(!sal){
        return sal;
    }
    if(sal==='0-70000'){
        return '$70,000'
    }
    else if(sal==='70000-100000'){
        return '$70,000 - $100,000'
    }
   else if(sal==='100000-120000'){
        return '$100,000 - $120,000'
    }
   else if(sal==='120000-150000'){
        return '$120,000 - $150,000'
    }
    else if(sal==='150000-180000'){
        return '$150,000 - $180,000'
    }
   else if(sal==='180000-220000'){
        return '$180,000 - $220,000'
    }
    else{
        return '$220,000 +'
    }
}

export const checkPath =(type)=>{
    let path = "";
  if (type === "employer") {
    return  path = "/dashboard/emp";
  } else if (type === "admin") {
    return path = "/dashboard/super";
  } else if (type === "recruiter") {
   return path = "/dashboard/spotter";
  } else {
    return path;
  }
}

export const getCurrentYear = () => {
    return new Date().getFullYear()
}
