import React, { lazy ,Suspense} from "react";
import Splash from "../Splash/index";
import { Switch, Route } from "react-router-dom";
//import Header from "../../pages/Registration/RegistrationHearder/RegistrationHeader";
import Footer from "../../pages/Registration/RegistrationFooter/RegistrationFooter";
import Header from '../common/Header';
import { useLocation } from "react-router-dom";
// import CreateAccount from "../../pages/Registration/CreateAccount/CreateAccount";
import "../../assets/reg/css/colors/green-style.scss";
import "../../assets/reg/plugins/css/plugins.scss";
import "../../assets/reg/plugins/css/plugins-icon.css";
import "../../assets/dashboard/plugins/css/plugins-icon.css";
import "../../assets/reg/css/style.scss";
import { registrationRoutes } from "../../utils/routes";
function BrowseLayout({ component: Component, ...rest }) {
  return (
    <>
     <div className="main-layout" id="websiteStyles">
     <div className="wrapper">
          <Header />
          <div>
            <Suspense fallback={<Splash />}>
            <Switch>
            <Route {...rest} render={(props) => <Component {...props} />} />
          </Switch>
            </Suspense>
          </div>
        
         </div>
        </div>
     
    </>
  );
}

export default BrowseLayout;
