export const ERROR = "Something went wrong, please try again later";
export const ERRORPASSWORD =
  "The password is invalid or the user does not have a password";
export const SUCCESS = "Data Saved successfully";
export const SENT = "Email sent successfully";
export const PASSWORDUPDATED = "Password Updated Successfully";
export const UPLOADRESUME = "Resume Uploded Successfully";
export const PROFILEUPDATED = "Profile Updated Successfully";
export const LOCATION = "Location data not available yet.";
export const PROCESSING = "Processing...";
export const NO_DATA = "No data found";

export const SkillSet = [
  "iOS",
  "Android",
  "Java",
  "Angular",
  "React",
  "C++",
  "Object Oriented Programing",
  "Database Architecture",
  "Node.js",
  "CSS",
  "Python",
  "Optimization",
  "Agile",
  "Data Structures",
  "API",
  "Unit Testing",
  "AI",
  "ML",
  "AR&VR",
  "IoT",
  "DevOps",
  "Big Data",
  "NLP",
  "Selenium",
  "CI/CD",
  "ETL",
  "Kotlin",
  "Swift",
  "Javascript",
  "Typescript",
];

export const userType = {
  admin: "admin",
  employer: "employer",
  candidate: "candidate",
  recruiter: "recruiter",
  teamMember: "team-member",
  referral: "referral",
};

export const jobTransaction = {
  //not set yet----
  JobStatusNotApprove: "Not Approve",
  JobStatusApprove: "Approve",
  JobStatusAccept: "Accept",
  JobStatusReject: "Reject",
  JobStatusPass: "Pass",
  JobStatusActive: "Active",
  // -------
  ProfileStatusInactive: "in-active",
  ProfileStatusReview: "review",
  ProfileStatusInterviewing: "interviewing",
  ProfileStatusWaitingForApproval: "awaiting-approval",
  ProfileStatusScheduleinterview: "schedule-interview",
  ProfileStatuswaitingforfeedback: "waiting-for-feedback",
  ProfileStatusoffer: "offer",
  ProfileStatushired: "hired",
  ProfileStatusavailable: "available",
  ProfileStatusRejected: "rejected",
};
export const jobStatus = {
  awaitingActivation: "awaiting-activation",
  approved: "approved",
  activated: "activated",
  deActivated: "de-activated",
};
export const recruiterStatus = {
  active: "active",
  inactive: "inactive",
};

export const categories = [
  { name: "Systems Engineering", value: "systems-engineering" },
  { name: "Network Engineering", value: "network-engineering" },
  { name: "Backend Engineering", value: "backend-engineering" },
  { name: "Frontend Engineering", value: "front-end-engineering" },
  { name: "Mobile Engineering", value: "mobile-engineering" },
  { name: "Security Engineering", value: "security-engineering" },
  { name: "Embedded Software", value: "embedded-software" },
  { name: "Artificial Intelligence", value: "artificial-intelligence" },
  { name: "Machine Learning", value: "machine-learning" },
  { name: "Quality Assurance", value: "quality-assurance" },
  { name: "Data Science", value: "data-science" },
  { name: "Product Project Management", value: "product-project-management" },
  { name: "Design", value: "design" },
  { name: "Web Development", value: "web-development" },
  { name: "Fullstack Eengineering", value: "full-stack-engineering" },
];
// export const experience = {
//   junior: "junior",
//   midLevel: "midLevel",
//   senior: "senior",
//   manager: "manager",
//   seniorManager: "seniorManager",
//   director: "director",
//   executive: "executive",
//   any: "any",
// };
export const experienceD = [
  { name: "junior (0-2 YOE)", value: "junior" },
  { name: "MidLevel (2-4 YOE)", value: "mid-level" },
  { name: "Senior (5+ YOE)", value: "senior" },
  { name: "Manager", value: "manager" },
  { name: "Senior Manager", value: "senior-manager" },
  { name: "Director", value: "director" },
  { name: "Executive", value: "executive" },
  { name: "Staff (7+ Year)", value: "staff" },
  { name: "Principal (10+ Year)", value: "principal" },
  { name: "Any", value: "any" },
];
// export const jobType = {
//   fullTime: "fullTime",
//   partTime: "partTime",
//   freelancer: "freelancer",
// };
export const jobType = [
  { name: "Full Time", value: "full-time" },
  { name: "Part Time", value: "part-time" },
  { name: "Freelancer", value: "freelancer" },
];
// export const salary = {
//   first: "0-70000",
//   second: "70000-100000",
//   third: "100000-120000",
//   fourth: "120000-150000",
//   fifth: "150000-180000",
//   sixth: "180000-220000",
//   seventh: "220000-220000+",
// };
export const salary = [
  { name: "- $70,000", value: "0-70000" },

  { name: "$70,000 - $100,000", value: "70000-100000" },
  { name: " $100,000 - $120,000", value: "100000-120000" },
  { name: " $120,000 - $150,000", value: "120000-150000" },
  { name: "$150,000 - $180,000", value: "150000-180000" },
  { name: "$180,000 - $220,000", value: "180000-220000" },
  { name: "$220,000 +", value: "220000-220000+" },
];

export const jobLocationType = [
  { name: "Remote", value: "remote" },
  { name: "Hybrid", value: "hybrid" },
  { name: "In Office", value: "in-office" },
];
// export const education = {
//   associate: "associate",
//   bachelor: "bachelor",
//   master: "master",
//   doctorate: "doctorate",
//   notRequired: "notRequired",
// };
export const education = [
  { name: "Associate Degree", value: "associate" },
  { name: "Bachelor's Degree", value: "bachelor" },
  { name: "Master’s Degree", value: "master" },
  { name: "Doctoral Degree", value: "doctorate" },
  { name: "Not Required", value: "not-required" },
];
// export const typeOfService = {
//   direct: "direct",
//   contract: "contract",
//   contractToHire: "contractToHire",
// };
export const typeOfService = [
  { name: "Direct Placement", value: "direct" },
  { name: "Contractor", value: "contract" },
  { name: "Contract to Hire", value: "contract-to-hire" },
];
export const defaultProfilePages = {
  mainLayout: "mainLayout",
  changePassword: "changePassword",
  editProfile: "editProfile",
  byResume: "byResume",
};
export const defaultJobPages = {
  jobList: "jobList",
  viewJob: "viewJob",
  refer: "refer",
  referAFriend: "referAFriend",
};
export const tab = {
  myProfile: "my-profile",
  jobs: "jobs",
  referrals: "referrals",
};

export const employerSidebar = [
  {
    name: "Dashboard",
    path: "/dashboard/emp",
    icon: "fa fa-bullseye",
    links: ["/dashboard/emp"],
  },
  {
    name: "Candidates",
    path: "/dashboard/candidate",
    icon: "ti ti-user",
    links: ["/dashboard/candidate"],
  },
  {
    name: "Jobs",
    path: "/dashboard/joblist",
    icon: "ti ti-folder",
    links: [
      "/dashboard/joblist",
      "/dashboard/applydetail",
      "/dashboard/editjob",
      "/dashboard/jobapplydetailspotter",
      "/dashboard/createjob",
    ],
  },
  {
    name: "Interviews",
    path: "/dashboard/interviews",
    icon: "ti ti-clipboard",
    links: [
      "/dashboard/interviews",
      "/dashboard/intdetails",
      "/dashboard/intpanel",
      "/dashboard/interviewsdetails",
    ],
  },
  {
    name: "Team",
    path: "/dashboard/team",
    icon: "ti ti-teamspotters",
    links: ["/dashboard/team", "/dashboard/edit-team"],
  },
  {
    name: "Company",
    path: "/dashboard/company",
    icon: "ti ti-clipboard",
    links: ["/dashboard/company", "/dashboard/companyedit"],
  },
];

export const recruiterSidebar = [
  {
    name: "Dashboard",
    path: "/dashboard/spotter",
    icon: "fa fa-bullseye",
    links: ["/dashboard/spotter"],
  },
  {
    name: "Candidates",
    path: "/dashboard/candidate",
    icon: "ti ti-user",
    links: ["/dashboard/candidate", "/dashboard/editcandidate"],
  },
  {
    name: "Add Candidates",
    path: "/dashboard/addcandidate",
    icon: "ti ti-ruler-pencil",
    links: ["/dashboard/addcandidate"],
  },
  {
    name: "Jobs",
    path: "/dashboard/joblist",
    icon: "ti ti-folder",
    links: [
      "/dashboard/joblist",
      "/dashboard/applydetail",
      "/dashboard/editjob",
      "/dashboard/jobapplydetailspotter",
      "/dashboard/createjob",
    ],
  },
  {
    name: "Interviews",
    path: "/dashboard/interviews",
    icon: "ti ti-clipboard",
    links: [
      "/dashboard/interviews",
      "/dashboard/intdetails",
      "/dashboard/intpanel",
      "/dashboard/interviewsdetails",
    ],
  },
  {
    name: "Profile",
    path: "/dashboard/profile",
    icon: "ti ti-folder",
    links: ["/dashboard/profile"],
  },
];

export const adminSidebar = [
  {
    name: "Dashboard",
    path: "/dashboard/super",
    icon: "fa fa-bullseye",
    links: ["/dashboard/super"],
  },
  {
    name: "Candidates",
    path: "/dashboard/candidate",
    icon: "ti ti-user",
    links: ["/dashboard/candidate"],
  },
  {
    name: "Referrals",
    path: "/dashboard/referrals",
    icon: "ti ti-user",
    links: ["/dashboard/referrals"],
  },
  {
    name: "Jobs",
    path: "/dashboard/joblist",
    icon: "ti ti-folder",
    links: [
      "/dashboard/joblist",
      "/dashboard/applydetail",
      "/dashboard/editjob",
      "/dashboard/jobapplydetailspotter",
      "/dashboard/createjob",
    ],
  },
  {
    name: "Interviews",
    path: "/dashboard/interviews",
    icon: "ti ti-ruler-pencil",
    links: [
      "/dashboard/interviews",
      "/dashboard/intdetails",
      "/dashboard/intpanel",
      "/dashboard/interviewsdetails",
    ],
  },
  {
    name: "Recruiters",
    path: "/dashboard/recruiterlst",
    icon: "ti ti-clipboard",
    links: ["/dashboard/recruiterlst"],
  },
  {
    name: "Employers",
    path: "/dashboard/emplst",
    icon: "ti ti-clipboard",
    links: ["/dashboard/emplst", "/dashboard/empinfo"],
  },
];

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua & Deps",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Rep",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo Democratic Rep",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland Republic",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar, Burma",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "St Kitts & Nevis",
  "St Lucia",
  "Saint Vincent & the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay ",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const headerList = [
  { path: "/hiretalent", name: " Hire Talent" },
  { path: "/iamgeek", name: "I’m a Geek" },
  { path: "/techlab", name: "Tech Lab" },
  { path: "/jobs", name: "Find Jobs" },
  { path: "/myaccount", name: "My Account" },
];
export const techLabList = [
  { img: "mobile_app", name: "Mobile App" },
  { img: "proto_type", name: "Prototype" },
  { img: "mvp", name: "MVP" },
  { img: "software", name: "Software" },
];

export const techLabContent = [
  {
    header: "Mobile App Development",
    content:
      "We have a skilled team that can develop any type of mobile application. Whether it is an iOS, Android, or Hybrid, we can build it.  We are experts in Swift, Objective-C, React Native, Flutter, and many more modern technologies. Our clients range in industries like Food, Taxis, E-Learning, E-commerce, Educational, Grocery, Dating, Health, etc.",
    header2: "Want to learn more?",
    img: "mobile_app",
    btn1: "Get in contact",
    btn2: "Back to top",
  },
  {
    header: "Prototype Development",
    content:
      "Our experts create high-fidelity prototypes that demonstrate the user experience and showcase how your system will look, work  and operate.",
    header2: "Want to learn more?",
    img: "mobile_app",
    btn1: "Get in contact",
    btn2: "Back to top",
  },
  {
    header: "MVP Development",
    content:
      "With guided workshops and sessions, our experienced consultants help you transform your product idea into a commercially viable product that can help you start with your operations.",
    header2: "Want to learn more?",
    img: "mobile_app",
    btn1: "Get in contact",
    btn2: "Back to top",
  },
  {
    header: "Software Development",
    content:
      "Following launch, our team can help scale your product with additional features, all while supporting your maintenance needs. Our developers write code, test the product to perfection, and iterate on your design to bring your final vision to life.",
    header2: "Want to learn more?",
    img: "mobile_app",
    btn1: "Get in contact",
    btn2: "Back to top",
  },
];

export const startupStage = [
  { name: "Seed", value: "seed" },
  { name: "Early", value: "early" },
  { name: "Growth", value: "growth" },
  { name: "Expansion", value: "expansion" },
  { name: "Ipo", value: "ipo" },
];

export const notification = {
  connect: "connect",
  disconnect: "disconnect",
  create: "notification-create",
  hide: "notification-hide",
};
