import  { lazy } from "react";
import ConfirmEmailEmployer from "../pages/ConfirmEmail/ConfirmEmailEmployer";

const dashboardPrefix = "/dashboard";
const registratonPrefix = "/registration";

// ****************Dashboard Layout******************

const DashboardAddCandidate = lazy(() =>
  import("../pages/Dashboard/DashboardAddCandidate/DashboardAddCandidate")
);
const DashboardSuperAdmin = lazy(() =>
  import("../pages/Dashboard/DashboardSuperAdmin/DashboardSuperAdmin")
);
const DashboardCandidate = lazy(() =>
  import("../pages/Dashboard/DashboardCandidate/DashboardCandidate")
);
const DashboardJobList = lazy(() =>
  import("../pages/Dashboard/JobList/JobList")
);
const DashboardCreateJob = lazy(() =>
  import("../pages/Dashboard/CreateJob/CreateJob")
);
const DashboardCompanyDetails = lazy(() =>
  import("../pages/Dashboard/DashboardCompanyDetails/CompanyDetails")
);
const DashboardCompanyEdit = lazy(() =>
  import("../pages/Dashboard/DashboardCompanyDetails/CompanyEdit/CompanyEdit")
);
const DashboardJobApplyDetailSpotter = lazy(() =>
  import("../pages/Dashboard/JobApplyDetailsSpotter/JobApplyDetailsSpotter")
);
const DashboardApplyDetail = lazy(() =>
  import("../pages/Dashboard/JobApplyDetailEmp/JobApplyDetailEmp")
);
const DashboardEditJob = lazy(() =>
  import("../pages/Dashboard/CreateJob/CreateJob")
);
const DashboardRecrutierList = lazy(() =>
  import("../pages/Dashboard/RecruiterList/RecruiterList")
);
const DashboardSpotter = lazy(() =>
  import("../pages/Dashboard/DashboardSpotter/DashboardSpotter")
);
const DashboardSpotterProfile = lazy(() =>
  import("../pages/Dashboard/SpotterProfile/SpotterProfile")
);
const DashboardSpotterEdit = lazy(() =>
  import("../pages/Dashboard/SpotterEdit/SpotterEdit")
);
const DashboardInterviews = lazy(() =>
  import("../pages/Dashboard/Interview/Interview")
);
const Team = lazy(() => import("../pages/Dashboard/Team/Team"));
const AEinterview = lazy(()=>import("../pages/Dashboard/Interview/AddorEditInterview"))
const Edit = lazy(() => import("../pages/Dashboard/EmployerTeamEdit/EmployerTeamEdit"));
const EmpoloyView = lazy(() => import("../components/employer/EmpoloyView"));
const InterviewDetails = lazy(() =>
  import("../pages/Dashboard/Interview/interviewDetails")
);
const DashboardEmployer = lazy(() =>
  import("../pages/Dashboard/DashboardEmployer/DashboardEmployer")
);
const Employer = lazy(() => import("../pages/Dashboard/Employer/Employer"));

// ******************Home Layout***************

const Home = lazy(() => import("../pages/Home/Home"));
const About = lazy(() => import("../pages/About/About"));
const HireTalent = lazy(() => import("../pages/HireTalent/HireTalent"));
const TechLab = lazy(() => import("../pages/TechLab/TechLab"));
const IAmGeek = lazy(() => import("../pages/IAmGeek/IAmGeek"));
const BecomeSpotter = lazy(() =>
  import("../pages/BecomeSpotter/BecomeSpotter")
);
const ContactUs = lazy(() => import("../pages/ContactUs/ContactUs"));
const Faq = lazy(() => import("../pages/Faq/Faq"));
const Privacy = lazy(() => import("../pages/Privacy/Privacy"));
const Terms = lazy(() => import("../pages/Term & Condition/Terms"));
const Login = lazy(() => import("../pages/Login/Login"));
const SignUp = lazy(() => import("../pages/Signup/Signup"));
const ThanksJob = lazy(() => import("../pages/ThanksJob/ThanksJob"));
const Reminder = lazy(() => import("../pages/Reminder/Reminder"));
const ResetPassword = lazy(() => import("../pages/ResetPassword/ResetPassword"));
const signIn = lazy(() => import("../pages/BrowserJob/ReferAFriendSignIn/SignIn"));
const referConfirmation = lazy(()=> import("../pages/BrowserJob/ReferSignupConfirmation/ReferSignupConfirm"));
const ReferConfirmationPage = lazy(()=> import("../pages/BrowserJob/Refer-Confirmarion-Page/ReferConfirmationPage"));
const JobApplyDetail = lazy(() =>import("../pages/JobApplyDetails/JobApplyDetails"));
const joinCompany = lazy(()=>import("../pages/JoinCompanyAccount/JoinCompanyAccount"))
const ConfirmMail = lazy(() => import("../pages/ConfirmEmail/ConfirmEmail"));
const ConfirmMailTeam = lazy(() => import("../pages/ConfirmEmail/ConfirmEmailTeam"));
const ReferDashboard = lazy(() => import("../pages/refer/ReferDashboard"));

// **************Registration Layout***********

const RegistrationAccount = lazy(() =>
  import("../pages/Registration/Registration")
);

export const dashboardRoutes = [
  // **************Dashboard Routes************

  {
    path: dashboardPrefix + "/addcandidate",
    component: DashboardAddCandidate,
  },
  {
    path: dashboardPrefix + "/editcandidate/:id",
    component: DashboardAddCandidate,
  },
  {
    path: dashboardPrefix + "/emp",
    component: DashboardEmployer,
  },
  {
    path: dashboardPrefix + "/team",
    component: Team,
  },
  {
    path: dashboardPrefix + "/edit-team",
    component: Edit,
  },
  {
    path: dashboardPrefix + "/intpanel",
    component: AEinterview
  },
  {
    path: dashboardPrefix + "/empinfo",
    component: EmpoloyView,
  },
  {
    path: dashboardPrefix + "/interviewsdetails",
    component: InterviewDetails,
  },

  {
    path: dashboardPrefix + "/super",
    component: DashboardSuperAdmin,
  },
  {
    path: dashboardPrefix + "/candidate",
    component: DashboardCandidate,
  },
  {
    path: dashboardPrefix + "/referrals",
    component: DashboardCandidate,
  },
  {
    path: dashboardPrefix + "/joblist",
    component: DashboardJobList,
  },
  {
    path: dashboardPrefix + "/createjob",
    component: DashboardCreateJob,
  },
  {
    path: dashboardPrefix + "/company",
    component: DashboardCompanyDetails,
  },
  {
    path: dashboardPrefix + "/companyedit",
    component: DashboardCompanyEdit,
  },
  {
    path: dashboardPrefix + "/jobapplydetailspotter",
    component: DashboardJobApplyDetailSpotter,
  },
  {
    path: dashboardPrefix + "/applydetail",
    component: DashboardApplyDetail,
  },
  {
    path: dashboardPrefix + "/editjob",
    component: DashboardEditJob,
  },
  {
    path: dashboardPrefix + "/recruiterlst",
    component: DashboardRecrutierList,
  },
  {
    path: dashboardPrefix + "/spotter",
    component: DashboardSpotter,
  },
  {
    path: dashboardPrefix + "/profile",
    component: DashboardSpotterProfile,
  },
  {
    path: dashboardPrefix + "/profileEdit",
    component: DashboardSpotterEdit,
  },
  {
    path: dashboardPrefix + "/interviews",
    component: DashboardInterviews,
  },
  {
    path: dashboardPrefix + "/emplst",
    component: Employer,
  },
];
// Dashboard/Employer/Employer
// *************Home Routes*************
export const homeRoutes = [
  {
    path: "/about",
    component: About,
  },
  {
    path: "/hiretalent",
    component: HireTalent,
  },
  {
    path: "/techlab",
    component: TechLab,
  },
  {
    path: "/iamgeek",
    component: IAmGeek,
  },
  {
    path: "/becomespotter",
    component: BecomeSpotter,
  },
  {
    path: "/contactus",
    component: ContactUs,
  },
  {
    path: "/faq",
    component: Faq,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    component: Terms,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/thanksjob",
    component: ThanksJob,
  },
  {
    path: "/reminder",
    component: Reminder,
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
  },
  {
    path: "/signin",
    component: signIn,
  },
  {
    path: "/referrer/confirm-referrer-sign-up",
    component: referConfirmation,
  },
  {
    path: "/referral-confirmation/:email",
    component: ReferConfirmationPage,
  },
  {
    path: "/jobs/:id",
    component: JobApplyDetail,
  },
  {
    path: "/confirmemail/team-member/:token",
    component: ConfirmMailTeam,
  },
  {
    path: "/confirmemail/employer/:confirmationToken",
    component: ConfirmEmailEmployer,
  },
  {
    path: "/confirmemail/:token",
    component: ConfirmMail,
  },
  {
    path:"/joincompany/:id",
    component: joinCompany,
  },
  {
    path: "/referrer/dashboard",
    component: ReferDashboard,
  },
  {
    path: "/",
    component: Home,
  },
];
export const referrerRoutes = [
  {
    path: "/referrer/dashboard",
    component: ReferDashboard,
  },
];
// ********Registration Routes**********
export const registrationRoutes = [
  {
    path: registratonPrefix + "/candidatecontact",
    component: RegistrationAccount,
  },
  {
    path: registratonPrefix + "/account",
    component: RegistrationAccount,
  },
  {
    path: registratonPrefix + "/accounte",
    component: RegistrationAccount,
  },
  {
    path: registratonPrefix + "/setcompany",
    component: RegistrationAccount,
  },
  {
    path: registratonPrefix + "/createjob",
    component: RegistrationAccount,
  },
  {
    path: registratonPrefix + "/reviewjob",
    component: RegistrationAccount,
  },
];
