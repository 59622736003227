import { toast } from 'react-toastify';

export const notify = (type, message) => {
    
    switch (type) {
        case "success":
            return toast.success(message);
        case "error":
            return toast.error(message);
        case "info":
            return toast.info(message);
        case "warn":
            return toast.warn(message);
        default:
            return toast.success(message);
    }
}