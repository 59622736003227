import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Notification from "./Notification";

export default function Notifications({
  notifications,
  logoutcheck1,
  handleLogoutcheck1,
  isLoading,
  setIsLoading,
  count,
  loadMore,
  handleLoadMore,
  setNotifications
}) {
  return (
    <div
      className={logoutcheck1 ? "dropdown-menu show shadow-box" : "dropdown-menu shadow-box"}
      aria-labelledby="dropdownMenuButton"
      onClick={handleLogoutcheck1}
      style={{ width: "350px" }}
    >
      <li className="external" style={{ padding: "0 15px" }}>
        <h5 className="pt-4 d-flex">
          <span className="bold">Show Notifications</span>
          <span className="spacer"></span>
          {count > 0 && (
            <button className="btn btn-success noti-button btn-new">{`New ${count}`}</button>
          )}
        </h5>
      </li>
      <div className="notification_block" style={{ padding: "0 15px",paddingBottom: "15px"}}>
        {isLoading && <div className="custloader"></div>}
        {notifications.map((item, i) => (
          <Notification 
            key={item._id} 
            item={item} 
            scroll={true} 
            lastId={notifications[notifications.length-1]._id}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setNotifications={setNotifications}
          />
        ))}
      </div>
      {loadMore && (
        <li style={{ textAlign: 'center', background: '#22b57324' }}>
          {/* <Link
            className="text-center"
            onClick={handleLoadMore}
          >
            <strong><b style={{fontWeight: "700",color:"#333"}}>Load More</b></strong>
        </Link> */}
          {!isLoading ? (
            <Button
              onClick={handleLoadMore}
              style={{ fontWeight: "700", color: "#333",marginTop: "10px",marginBottom: "10px",marginRight: "5px" }}
              variant="text"
            >
              Load More
            </Button>
          ) : (
            <LoadingButton 
              style={{ fontWeight: "700", color: "#333",marginTop: "10px",marginBottom: "10px",marginRight: "5px" }}
              loading={true} 
              variant="text" 
              disabled
            >
              Load More
            </LoadingButton>
          )}
        </li>
      )}
      {/* {notifications.length > 0 &&   <li style={{padding:"0 15px"}}>
      <Link
        className="text-center"
        onClick={readAllNotification}
      >
        <strong><b style={{fontWeight: "700",color:"#333"}}>Close all Notifications</b></strong>
      </Link>
      </li> } */}
    </div>
  );
}
