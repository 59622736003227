import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";

export default function Menu({ item, setSideToggle, showSideMenu }) {
  const location = useLocation();

  const [active, setActive] = useState(false);
  useEffect(() => {
    let count = 0;
    item.links.map((item) => {
      if (location.pathname.includes(item)) {
        count++;
      }
    });
    if (count > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname]);
  return (
    <>
      {showSideMenu ? (
        <li
          className={active ? "active" : "inactive"}
          onClick={() => setSideToggle(false)}
        >
          <Link to={item.path}>
            <i
              className={item.icon}
              style={{
                color: active ? "#22b573" : "#fff",
              }}
            ></i>
            <>{item.name}</>
          </Link>
        </li>
      ) : (
        <li
          className={active ? "active" : "inactive"}
          style={{ textAlign: "center" }}
          onClick={() => setSideToggle(false)}
        >
          <Tooltip className="" title={item.name} placement="right" arrow>
            <Link to={item.path}>
              <i
                className={item.icon}
                style={{
                  color: active ? "#22b573" : "#fff",
                }}
              ></i>
            </Link>
          </Tooltip>
        </li>
      )}
    </>
  );
}
