import React, { useState } from "react";
import Button from "../../../components/common/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Error from "../../../components/common/Error";
import { Link } from "react-router-dom";
import * as service from "../../../utils/service";
import * as constant from "../../../utils/constant";
import { notify } from "../../../utils/notification";

export default function Support({ closePopup }) {

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const schema = Yup.object({
    name: Yup.string().required("Name is required"),
    message: Yup.string().required("Message is Required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {

    setLoading(true);
    service.support(data).then(resp=>{
        if(resp?.data?.status){
            notify('success', resp?.data?.message);
            setLoading(false);
            closePopup(true);
        }
    }).catch(err => {
        notify('error', err?.response?.data?.message || constant.ERROR);
        setLoading(false);
    })
  }

  const handleClickSubmit = (e) => {
    setSubmitted(true);
  }

  const onCancel = (e) => {
    setValue('name', '');
    setValue('message', '');
    setLoading(false);
    setSubmitted(false);
    closePopup(true);
  }
  

  

  return (
    <div id="SupportMessage" className="overlay">
      <div id="popupBodySupport">
        <h4 className="modal-title">Do you need help with something?</h4>
        <a id="cerrar" style={{ cursor: "pointer" }} onClick={onCancel}>
          &times;
        </a>
        <hr />
        <div className="popupContent">
          <p>Send us a message and we will follow up within 24hs.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  {...register("name")}
                />
                {submitted && errors.name && (
                    <Error message={errors.name.message}/>
                )}
              </div>
              <textarea
                className="form-control big-height"
                placeholder="Message"
                {...register("message")}
              ></textarea>
              {submitted && errors.message && (
                <Error message={errors.message.message}/>
              )}
            </div>
            <hr />
            <div className="text-center mt-5">
              <Button variant="cancel" onClick={onCancel}>
                Cancel
              </Button>
              <Button onClick={handleClickSubmit} loading={loading}>
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
