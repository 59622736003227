import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Logout({ showSideMenu, onClick, title }) {
  return (
    <>
      {showSideMenu ? (
        <li>
          <Link to="/login" onClick={onClick}>
            <i className="ti ti-shift-right"></i>
            {showSideMenu && <>{title}</>}
          </Link>
        </li>
      ) : (
        <li style={{ textAlign: "center" }}>
          <Tooltip className="" title={title} placement="right" arrow>
            <Link to="/login" onClick={onClick}>
              <i className="ti ti-shift-right"></i>
              {showSideMenu && <>Log Out</>}
            </Link>
          </Tooltip>
        </li>
      )}
    </>
  );
}
