import React from "react";
import socketIOClient from "socket.io-client";
import * as session from "../../utils/session";

export const SocketContext = React.createContext("socket");

export default class Socket extends React.Component {
    constructor(props) {
        super(props);
        this.endPoint = process.env.React_App_NODE_ENV === "dev"
        ? process.env.React_App_API
        : process.env.React_App_Prod_API;
        this.userId = session.getId();
        this.options = { query: `userId=${this.userId}` };
        this.socket = socketIOClient(this.endPoint, this.options);
    }


    render() {
        return (
          <SocketContext.Provider value={this.socket}>
            {this.props.children}
          </SocketContext.Provider>
        );
    }
}