import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../common/Footer";
import "../../assets/css/meanmenu.css";
import "../../assets/css/responsive.scss";
import "../../assets/css/index.scss";
import "../../assets/css/owl.carousel.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style-website.scss";
import "../../assets/css/icofont.min.css";
import "../../assets/dashboard/plugins/css/plugins-icon.css";

function ReferLayout({ component: Component, ...rest }) {
  return (
    <>
      <div className="main-layout" id="websiteStyles">
        <div className="wrapper">
          <Switch>
            <Route {...rest} render={(props) => <Component {...props} />} />
          </Switch>
        </div>
      </div>
    </>
  );
}

export default ReferLayout;
