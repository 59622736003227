import { GET_DASHBOARD_DATA } from "../../../../redux/config";
import * as session from "../../../../utils/session";

const initialState = {
  companyDetails: null,
  jobList: [],
  teamList: [],
  recruiterList: [],
  candidateList: [],
  loading: true,
  selectedJobId: null,
  selectedJob: null,
  selectedEditJob: null,
  EditJobItem:null,
  recruiterById: null,
  teamEdit: null,
  dashboardData: {},
  interViewData: null,
  refrelData: null,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMPANY":
      localStorage.setItem("companyId", action.data.data?.company?._id);
      localStorage.setItem(
        "company",
        JSON.stringify(action.data?.data?.company)
      );
      localStorage.setItem(
        "teamMember",
        JSON.stringify(action.data?.data?.user)
      );
      session.setCandidateId(action.data.data?._id);
      return {
        ...state,
        companyDetails: action.data,
        loading: action.loading,
      };
    case "GET_JOBLIST_BY_USER":
      return {
        ...state,
        jobList: action.data,
        loading: action.loading,
      };
    case "GET_RECRUITERLIST_BY_USER":
      return {
        ...state,
        recruiterList: action.data,
        loading: action.loading,
      };
    case "GET_RECRUITERLIST_BY_ID":
      return {
        ...state,
        recruiterById: action.data,
        loading: action.loading,
      };
    case "UPDATE_JOB_STATUS":
      return {
        ...state,
        jobList: action.data,
      };
    case "UPDATE_RECRUITER_STATUS":
      return {
        ...state,
        recruiterList: action.data,
      };
    case "SELECTED_JOBID":
      return {
        ...state,
        selectedJobId: action.data,
      };
    case "GET_JOB_BY_Id":
      return {
        ...state,
        selectedJob: action.data,
        selectedEditJob: action.data,
        loading: action.loading,
      };
    case "SELECTED_JOB":
      return {
        ...state,
        selectedJob: action.data,
      };
    case "DELETE_SELECTED_JOB":
      return {
        ...state,
        selectedEditJob: null,
      };
    case "GET_JOBLIST_BY_TEAM":
      return {
        ...state,
        teamList: action.data,
        loading: action.loading,
      };
    case "GET_TEAM_BY_ID":
      return {
        ...state,
        teamEdit: state.teamList.find((item) => item._id === action.data._id),
      };
    case "UPDETE_DATA_OF_TEAM":
      return {
        ...state,
        teamList: state.teamList.map((item) =>
          item._id === action.data.id ? action.data : item
        ),
      };
    case "GET_CANDIDATE_BY_USER":
      return {
        ...state,
        candidateList: action.data,
        loading: action.loading,
      };

    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.data,
        loading: action.loading,
      };
    case "GET_INTERVIEW_DATA": {
      return {
        ...state,
        interViewData: action.data,
      };
    }
    case "SAVE_REFFREL_DATA":
      return {
        ...state,
        refrelData: action.data,
      };
    case "EDIT_JOB_ITEM" :
      return{
        ...state,
        EditJobItem:action.data
      } 
    default:
      return state;
  }
};

export default companyReducer;
