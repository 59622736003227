import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/meanmenu.css';
import './assets/css/responsive.scss';
import './assets/css/index.scss';
import './assets/css/owl.carousel.min.css';
import './assets/css/bootstrap.min.css';
//import './assets/css/style.scss'
// import './assets/css/style.css'
// import './assets/reg/css/style.css'
// import './assets/reg/plugins/css/plugins.css'
import './assets/css/icofont.min.css'

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Router, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios'
import {setupAxios} from './redux/SetUpAxios'
//import Style from './assets/Styling'
const history = createBrowserHistory();

// setup axios interceptor
setupAxios(axios)


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter history={history}>
        <App />
    </BrowserRouter>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

reportWebVitals();
