const userIdName = "userId";
const tokenName = "token";
const userTypeName = "loginType";
const candidateIdName = "candidateId";
const companyIdName = "companyId";
const permissionName = "permission";
const RoleName = "role";

export const setId = (userId) => {
  return localStorage.setItem(userIdName, userId);
};

export const getId = () => {
  return localStorage.getItem(userIdName);
};

export const setToken = (token) => {
  return localStorage.setItem(tokenName, token);
};

export const getToken = () => {
  return localStorage.getItem(tokenName);
};

export const clearSession = () => {
  return localStorage.clear();
};

export const setUserType = (token) => {
  return localStorage.setItem(userTypeName, token);
};

export const getUserType = () => {
  return localStorage.getItem(userTypeName);
};

export const setCandidateId = (token) => {
  return localStorage.setItem(candidateIdName, token);
};

export const getCandidateId = () => {
  return localStorage.getItem(candidateIdName);
};
export const getCompanyId = (id) => {
  return localStorage.getItem(companyIdName);
};

export const setPermission = (name) => {
  return localStorage.setItem(permissionName, name);
};

export const getPermission = () => {
  return localStorage.getItem(permissionName);
};
export const setRole = (name) => {
  return localStorage.setItem(RoleName, name);
};

export const getRole = () => {
  return localStorage.getItem(RoleName);
};
