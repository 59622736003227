import React from "react";
import { Link,useHistory,useLocation } from "react-router-dom";
import { adminSidebar, employerSidebar, recruiterSidebar } from "../../../utils/constant";
import { LogOut } from "../../Signup/Store/action";
import { useDispatch } from "react-redux";
import * as session from "../../../utils/session";
import * as service from "../../../utils/service";
import Menu from "./Menu";
import Logout from "./Logout";

export default function DashboardSidebar({sideToggle, setSideToggle, showSideMenu}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const logout = () => {
    dispatch(LogOut());
  };
  let userId = session.getId();
  let role = session.getRole();
  const leaveTeam = () => {
    if (window.confirm("Are you sure want to leave from this team ?")) {
      service
        .leaveTeamMember(userId)
        .then((resp) => {
          if (resp.status) {
            console.log(resp.status);
            history.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  
  return (
    <div className="navbar-default sidebar" role="navigation">
      <div
        className={
          sideToggle
            ? "sidebar-nav navbar-collapse collapse show"
            : "sidebar-nav navbar-collapse collapse "
        }
      >
        {(localStorage.loginType === "employer" ||
          localStorage.loginType === "team-member") && (
          <ul className="nav" id="side-menu">
            {employerSidebar
              .filter((item) => {
                if (
                  localStorage.loginType === "team-member" &&
                  role !== "admin" &&
                  (item.path === "/dashboard/company" ||
                    item.path === "/dashboard/team" ||
                    item.path === "/dashboard/createjob")
                ) {
                  return false;
                } else {
                  return true;
                }
              })
              .map((item) => (
                <Menu 
                  key={item.name}
                  item={item}
                  setSideToggle={setSideToggle}
                  showSideMenu={showSideMenu}
                />
            ))}
            <Logout
              title="Log Out"
              onClick={logout}
              showSideMenu={showSideMenu}
            />
            {localStorage.loginType === "team-member" && (
              <>
              {showSideMenu ? (
              <li>
                <Link style={{ cursor: "pointer" }} onClick={leaveTeam}>
                  <i className="ti ti-view-list-alt"></i>Leave
                </Link>
              </li>) : (
                <li>
                  <Link style={{ cursor: "pointer", textAlign:"center" }} onClick={leaveTeam}>
                    <i className="ti ti-view-list-alt"></i>
                  </Link>
                </li>
              )}
              </>
            )}
          </ul>
        )}
        {localStorage.loginType === "recruiter" && (
          <ul className="nav" id="side-menu">
            {recruiterSidebar.map((item) => (
              <Menu 
                key={item.name}
                item={item}
                setSideToggle={setSideToggle}
                showSideMenu={showSideMenu}
              />
            ))}
            <Logout
              title="Log Out"
              onClick={logout}
              showSideMenu={showSideMenu}
            />
          </ul>
        )}
        {localStorage.loginType === "admin" && (
          <ul className="nav" id="side-menu">
            {adminSidebar.map((item) => (
              <Menu 
                key={item.name}
                item={item}
                setSideToggle={setSideToggle}
                showSideMenu={showSideMenu}
              />
            ))}
            <Logout
              title="Log Out"
              onClick={logout}
              showSideMenu={showSideMenu}
            />
          </ul>
        )}
      </div>
    </div>
  );
}
