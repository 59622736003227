import React, { Suspense } from "react";
import Splash from "../Splash/index";
import { Switch, Route } from "react-router-dom";
import "../../assets/reg/css/colors/green-style.scss";
import "../../assets/reg/plugins/css/plugins.scss";
import "../../assets/reg/plugins/css/plugins-icon.css";
import "../../assets/dashboard/plugins/css/plugins-icon.css";
import "../../assets/reg/css/style.scss";
import image_path from "../common/ImagePath";
import "./ConfirmEmailLayout.scss";

const { headerLogoImg } = image_path;

function ConfirmEmailLayout({ component: Component, ...rest }) {
  return (
    <>
      <Suspense fallback={<Splash />}>
        <div className="main-layout browse-company-jobs" id="websiteStyles">
          <div
            className="wrapper browse-jobs browse-company-jobs"
            id="registrationStyles"
          >
            <div className="header_logo_container">
              <a target="_blank">
                <img src={headerLogoImg} className="company_job_logo" />
              </a>
            </div>
          </div>

          <div className="wrapper">
            <div>
              <Switch>
                <Route {...rest} render={(props) => <Component {...props} />} />
              </Switch>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default ConfirmEmailLayout;
