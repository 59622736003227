import React, { useEffect, useState, useRef } from "react";
import { useLocation, useHistory} from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogOut } from "../../Signup/Store/action";
import * as session from "../../../utils/session";
import * as service from "../../../utils/service";
import $ from "jquery";
import "./DashboardHeader.scss";
import { notify } from "../../../utils/notification";
import {
  ERROR,
  notification,
} from "../../../utils/constant";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { candidateChangePassword ,bugReport} from "../../../utils/service";
import Socket from "../../../components/socket/Socket";
import DashboardSidebar from "../DashboardSidebar/DashboardSidebar";
import Notifications from "../../../components/notification/Notifications";
import { capitalize } from "../../../utils/commonFunctions";
import Button from "../../../components/common/Button";
import Support from "./Support";

const DashboardHeader = ({ collapseMenu, showSideMenu }) => {
  const history = useHistory();
  const container = useRef();
  const container2 = useRef();
  const dispatch = useDispatch();
  const [isLoading , setIsLoading] = useState(false)
  const [sideToggle ,setSideToggle] = useState(false)
  const [subimitted, setSubimitted] = useState(false);
  const [subimitted1, setSubimitted1] = useState(false);
  const [subimitted2, setSubimitted2] = useState(false);
  const [employerDetail, setEmployerDetail] = useState("");
  const [teamMemberDetail, setTeamMemberDetail] = useState("");
  const [notifications ,setNotifications] = useState([])
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoadingPassword , setIsLoadingPassword] = useState(false)
  const [isLoadingSupport, setIsLoadingSupport] = useState(false)
  const [isLoadingBug , setIsLoadingBug] = useState(false)
  const [logoutcheck, setLogoutcheck] = useState(false);
  const [logoutcheck1, setLogoutcheck1] = useState(false);

  React.useEffect(()=>{
    getNotification(page,limit)
  },[page])

  const handleLoadMore =(e) => {
    setIsLoading(true)
    setPage(prev=>prev+1)
  }

  const getNotification =(page, limit)=>{
    service.getNotification(page, limit).then(resp=>{
      setIsLoading(false)
      if(resp.data.status) {
        if(resp.data.data.notifications.length < limit){
          setLoadMore(false);
        }else {
          setLoadMore(true);
        }
        setNotifications(prevState=>[...prevState, ...resp.data.data.notifications])
        setCount(resp.data.data.count);
      }
    })
    .catch(err=>{
      console.log('err', err)
      setIsLoading(false)
    })
  }
  const handleSideToggle =()=>{
setSideToggle(!sideToggle)
  }
  const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
  const BugDataSchema = Yup.object({
    bugemail: Yup.string()
    .email("Enter valid email.")
    .required("Email is required."),
    bugUrl:Yup.string().required("Url is required.").matches(re,'URL is not valid.'),
    bugDescription: Yup.string()
    .required("Description is required.")
  
  });
  
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset:reset2 ,
    formState: { errors: errors2 },
    
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(BugDataSchema),
  });
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Old Password is required."),

    password: Yup.string()
      .required("Please Enter your password.")
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$",
        "Your password must be at least 6 characters with 1 uppercase letter, 1 lower case and 1 number."
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password does not match."
    ),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      return;
    }
   
    const formdata = {
      oldPassword: data.oldPassword,
      password: data.password,
    };
    setIsLoadingPassword(true);
    candidateChangePassword(formdata)
      .then((resp) => {
        setIsLoadingPassword(false);
        notify("success", "Password change successfully");
        reset();
        changePasswordClose();
      })
      .catch((err) => {
        console.log(err?.response?.data.message);
        setIsLoadingPassword(false);
        notify("error", err?.response?.data.message);
      });
  };

  const handleClickOutside = (event) => {
    if (container.current && !container.current.contains(event.target)) {
      setLogoutcheck(false);
    }
  };
  const handleClickOutside1 = (event) => {
    if (container2.current && !container2.current.contains(event.target)) {
      setLogoutcheck1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  },[handleClickOutside]);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside1, true);
    return () => {
      document.removeEventListener("click", handleClickOutside1, false);
    };
  },[handleClickOutside1]);
  const SubmitBugData = (data) => {
    if(!data.bugemail || !data.bugDescription ||!data.bugUrl){
      return
    }
    if(errors2.bugemail || errors2.bugDescription ||errors2.bugUrl){
      return
    }
   const formdata = {
      email: data.bugemail,
      url: data.bugUrl,
      description: data.bugDescription
  }
    setIsLoadingBug(true);
  bugReport(formdata).then(resp=>{
    if(resp?.data?.status){
      notify('success','Bug reported successfully')


    }
     reset2()
     closeBugForm()
     setIsLoadingBug(false);
   }).catch(err=>{
    notify("error",err?.response?.data?.message || ERROR)
    setIsLoadingBug(false);
   })
  };
  const SubmitSupportData = (data) => {
    setIsLoadingSupport(true)
  }
  
  const handleLogoutcheck = () => {
    setLogoutcheck(!logoutcheck);
  };

  useEffect(() => {
    if(logoutcheck1){
      readAllNotification();
    }
  },[logoutcheck1])

  const handleLogoutcheck1 = () => {
    setLogoutcheck1(!logoutcheck1);
  };
  const BugForm = () => {
    $("#BugMessage").css("visibility", "visible");
    $("#BugMessage").css("opacity", "1");
  };
  const closeBugForm = () => {
    $("#BugMessage").css("visibility", "hidden");
    $("#BugMessage").css("opacity", "0");
    setSubimitted(false);
  };

  const closeWelcomePopup = () => {
    localStorage.removeItem("redirectedFrom");
    $("#teamwelcome").css("visibility", "hidden");
    $("#teamwelcome").css("opacity", "0");
  };
  const supportMessage = () => {
    $("#SupportMessage").css("visibility", "visible");
    $("#SupportMessage").css("opacity", "1");
  };
  const closePopup = () => {
    $("#SupportMessage").css("visibility", "hidden");
    $("#SupportMessage").css("opacity", "0");
    setSubimitted2(false)
  };
  const changePasswordClose = () => {
    setSubimitted1(false);
    $("#changePassword").css("visibility", "hidden");
    $("#changePassword").css("opacity", "0");
  };
  const changePassword = () => {
    $("#changePassword").css("visibility", "visible");
    $("#changePassword").css("opacity", "1");
  };
  const logout = () => {
    dispatch(LogOut());
  };
  let userId = session.getId();
  const leaveTeam = () => {
    if (window.confirm("Are you sure want to leave from this team ?")) {
      service
        .leaveTeamMember(userId)
        .then((resp) => {
          if (resp.status) {
            console.log(resp.status);
            history.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
    

  let permission = session.getPermission();
  
  let role = session.getRole();
 

  const handleClickSubmit = () => {
    setSubimitted1(true);
  };
  const handleClickSubmit2 = () => {
    setSubimitted2(true);
  };

  const isRedirected = localStorage.getItem("redirectedFrom");
  const EmployerDetail = localStorage.getItem("company")==='undefined'?null:JSON.parse(localStorage.getItem("company"));
  const teamMemberDetails = localStorage.getItem("teamMember")==='undefined'?null:JSON.parse(localStorage.getItem("teamMember"));

  const welcomePopup = () => {
    $("#teamwelcome").css("visibility", "visible");
    $("#teamwelcome").css("opacity", "1");
  };
  useEffect(() => {
    if (isRedirected) {
      setEmployerDetail(EmployerDetail);
      if (teamMemberDetails) {
        setTeamMemberDetail(teamMemberDetails);
      }
      welcomePopup();
    }
  }, []);
const readAllNotification =()=>{
  setIsLoading(true)
  service.closeAllNotification().then(resp=>{
    if(resp.data.status) {
      setIsLoading(false)
    }
  }).catch(err=>{
    console.log('err', err)
    setIsLoading(false)
  })
}

const handleSocketConnect = (data) => {
  console.log("🚀 ~ handleSocketConnect ~ data", data)

}
const handleSocketDisconnect = (data) => {
    console.log("🚀 ~ handleSocketDisconnect ~ data", data)
    
}
const handleNotificationCreate = (data) => {
    console.log("🚀 ~ handleNotificationCreate ~ data", data)
    setNotifications(prevState=>{
        return [ data.notification, ...prevState];
    })
    setCount(data.count);
}

const handleNotificationHide = (data) => {
console.log("🚀 ~ handleNotificationHide ~ data", data)
    setCount(data.count);
    
      if(data.notification){
        setNotifications(prevState=>{
            return prevState.filter((item) => item._id !== data.notification._id)
        })
      }
}
  return (
    <>
    <Socket on={notification.connect} handle={handleSocketConnect}/>
    <Socket on={notification.disconnect} handle={handleSocketDisconnect}/>
    <Socket on={notification.create} handle={handleNotificationCreate}/>
    <Socket on={notification.hide} handle={handleNotificationHide}/>
      <nav
        className="navbar navbar-default navbar-static-top"
        style={{ marginBottom: "0" }}
      >
        <div className="navbar-header">
          <button
            type="button"
            className= "navbar-toggle" 
            
            aria-expanded={sideToggle?"true":"false"}
            onClick={handleSideToggle}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link to="/">
          <span className="navbar-brand">
            <img
              src={
                require("../../../assets/dashboard/img/logogeek_blanco.png")
                  .default
              }
              className="img-responsive"
              alt="Logo"
            />
          </span>
          </Link>
        </div>
        {/* /.navbar-header  */}

        <ul
          className="nav navbar-top-links navbar-left header-search-form hidden-xs"
          style={{ color: "white", marginLeft:"-22px" }}
          
        >
          <li>
            <a
              // href="#"
              className="menu-brand"
              id="menu-toggle"
              onClick={collapseMenu}
            >
              <img
              src={
                require("../../../assets/dashboard/img/grid.svg").default
              }
              className=""
              alt="Grid"
            />
            </a>
          </li>
        </ul>
        <ul className="nav navbar-top-links navbar-right mr-3" ref={container}>
          <li
            // className={logoutcheck ? "dropdown show" : "dropdown"}
            className={`${logoutcheck ? "dropdown show" : "dropdown"}`}
            onClick={handleLogoutcheck}
          >
            <a
              // href="#!"
              className="btn btn-lg toggle spintisetgs"
              aria-haspopup="true"
              aria-expanded={logoutcheck ? "true" : "false"}
              onClick={() => setLogoutcheck(!logoutcheck)}
            >
              <img
              src={
                require("../../../assets/dashboard/img/setting.svg").default
              }
              className="spin"
              alt="Setting"
            />
            </a>
            <ul
              className={logoutcheck ? "dropdown-menu show" : "dropdown-menu"}
              style={{ marginTop: "27px" }}
            >
              <li>
                <a 
                // href="javascript:;" 
                onClick={supportMessage} data-toggle="tooltip">
                  <i className="fa fa-envelope fa-fw"></i> Support
                </a>
              </li>
              <li>
                <a 
                // href="javascript:;" 
                onClick={changePassword} data-toggle="tooltip">
                  <i className="fa fa-user fa-fw"></i> Change Password
                </a>
              </li>
              <li>
                <Link to="/" onClick={logout}>
                  <i className="fa fa-sign-out fa-fw"></i> Logout
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        {localStorage.loginType !== "admin" && (
          <a
            // href="javascript:;"
            id="spinbug"
            className="btn btn-lg toggle spinbug nav navbar-top-links navbar-right"
            onClick={BugForm}
            style={{padding:"9px 14px"}}
          >
            <img
              src={
                require("../../../assets/dashboard/img/bug_repb.svg").default
              }
              className="ti-bug"
              alt="Bug"
            />
          </a>
        )}
        <a 
          // href="javascript:;"
          className="btn btn-lg toggle nav navbar-top-links navbar-right dropdown bell-icon" 
          onClick={handleLogoutcheck1}
          style={{padding:"10px 14px"}}
        >
          <img ref={container2}
            className={logoutcheck1 ? " dropdown mov" : "dropdown mov"}
          style={{height:"20px"}}
            aria-haspopup="true"
            aria-expanded={logoutcheck1 ? "true" : "false"}
            src={
              require("../../../assets/dashboard/img/notification.svg").default
            }
          />
            {count > 0 && (
                <span
                    className="task-notify noti-count"
                    style={{ top: "0", left: "26px", fontWeight: "800" }}
                >
                {count}
                </span>
            )}
            <Notifications
              notifications={notifications}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              logoutcheck1={logoutcheck1}
              handleLogoutcheck1={handleLogoutcheck1}
              count={count}
              loadMore={loadMore}
              handleLoadMore={handleLoadMore}
              setNotifications={setNotifications}
            />
        </a>

        <DashboardSidebar sideToggle={sideToggle} setSideToggle={setSideToggle} showSideMenu={showSideMenu}/>
      </nav>
      <Support closePopup={closePopup}/>
      <div id="changePassword" className="overlay">
        <div id="popupBodySupport">
          <h4 className="modal-title">
            Do you need to change or update your password?
          </h4>
          <a
            // href="javascript:;"
            id="cerrar"
            style={{ cursor: "pointer" }}
            onClick={changePasswordClose}
          >
            &times;
          </a>
          <hr />
          <div className="popupContent">
            <p>Please enter your new information below.</p>
            <form
              // onSubmit={formik.handleSubmit}
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter") e.preventDefault();
              }}
            >
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="oldPassword"
                    {...register("oldPassword")}
                    placeholder="Old Password"
                  />
                  {subimitted1 && errors.oldPassword && (
                    <div className="help-block with-errors">
                      <span id="colorStyle">{errors.oldPassword.message}</span>
                    </div>
                  )}
                  
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    {...register("password")}
                    placeholder="New Password"
                  />
                  {subimitted1 && errors.password && (
                    <div className="help-block with-errors">
                      <span id="colorStyle">{errors.password.message}</span>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    {...register("confirmPassword")}
                    placeholder="Confirm Password"
                  />
                  {subimitted1 && errors.confirmPassword && (
                    <div className="help-block with-errors">
                      <span id="colorStyle">
                        {errors.confirmPassword.message}
                      </span>
                    </div>
                  )}

                  
                </div>
              </div>
              <hr />
              <div className="text-center mt-5">
                <Button
                  variant="cancel"
                  onClick={changePasswordClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleClickSubmit} 
                  loading={isLoadingPassword}
                >
                  Change
                </Button>
                
              </div>
            </form>
          </div>
        </div>
      </div>

      <div id="BugMessage" className="overlay">
        <div id="popupBodySupport">
          <h4 className="modal-title">Please help us get better!</h4>
          <a
            // href="javascript:;"
            id="cerrar"
            style={{ cursor: "pointer" }}
            onClick={closeBugForm}
          >
            &times;
          </a>
          <hr />
          <div className="popupContent">
            <p>
              Even though we are constantly testing our app, sometimes we miss a
              bug that can be annoying to you, we really apologize for that.
            </p>
            <form onSubmit={handleSubmit2(SubmitBugData)}>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="User email"
                    name="bugemail"
                    {...register2("bugemail")}
                  />
                  {subimitted2 && errors2?.bugemail && (
                    <div className="help-block with-errors">
                      <span>{errors2?.bugemail.message}</span>
                    </div>
                  )}
                 
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="URL where the bug is"
                    name="bugUrl"
                    {...register2("bugUrl")}
                  />
                   {subimitted2 && errors2?.bugUrl && (
                  <div className="help-block with-errors">
                    <span>{errors2?.bugUrl.message}</span>
                  </div>
                )}
                </div>
                <textarea
                  name="bugDescription"
                  className="form-control"
                  {...register2("bugDescription")}
                  id="message"
                  cols="30"
                  rows="3"
                  placeholder="Description of the bug"
                ></textarea>
                {subimitted2 && errors2?.bugDescription && (
                  <div className="help-block with-errors">
                    <span>{errors2?.bugDescription.message}</span>
                  </div>
                )}
              </div>
              <hr style={{ marginTop: "-7px", marginBottom: "-28px" }} />
              <div className="text-center mt-5">
                <Button
                  variant="cancel"
                  onClick={closeBugForm}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleClickSubmit2} 
                  loading={isLoadingBug}
                >
                  Send
                </Button>
                
            
              </div>
            </form>
          </div>
        </div>
      </div>

      <div id="teamwelcome" className="overlay">
        <div id="popupBodySupport">
          <div className="text-center">
            {" "}
            <h4 className="modal-title">Welcome to {EmployerDetail?.name}’s hiring team!</h4>
          </div>
          <a
            // href="javascript:;"
            id="cerrar"
            style={{ cursor: "pointer" }}
            onClick={closeWelcomePopup}
          >
            &times;
          </a>
          <hr />
          <div className="popupContent text-center">
            {/* <p> */}
              {/* {capitalize(teamMemberDetail?.firstName)}, you are now a team member of {" "}
              {capitalize(employerDetail?.name)} as a {capitalize(teamMemberDetail?.role === "hiring_manager" ? "Hiring Manager" : teamMemberDetail?.role)}. Here, you can review candidates and track their interview process. Happy to have you on the team! */}
              {teamMemberDetail?.role === "admin" ? <p>
              {capitalize(teamMemberDetail?.firstName)} you have been added as an admin. 
              You can now create and edit a job, review candidates, 
              and track their interview process.
              </p> : teamMemberDetail?.role === "hiring_manager" ? <p>
              {capitalize(teamMemberDetail?.firstName)} you have been added as a hiring manager. 
              If you have any questions regarding your permissions, 
              please contact your account administrator.
              </p> : (
                <p>
                  {capitalize(teamMemberDetail?.firstName)} you have been added as a recruiter. 
                  If you have any questions regarding your permissions, 
                  please contact your account administrator.

                </p>
              )}
            {/* </p> */}
            <img
              src={
                require("../../../assets/Image/png/welcome.png")
                  .default
              }
              alt=""
              width="30%"
            />
          </div>
          <div className="text-center">
            <a
              // href="javascript:;"
              className="btn-savepreview small-btn mrg-bot-5 mrg-top-15"
              onClick={closeWelcomePopup}
            >
              Get started
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;

