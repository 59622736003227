export const setRegistrationType=(data) =>{
    return async (dispatch)=>{
        dispatch({
            type:"REGISTRATION_TYPE",
            data:data
        })
    }
}
export const setUserId=(data) =>{
    return async (dispatch)=>{
        dispatch({
            type:"USER_ID",
            data:data
        })
    }
}

export const JobDetails=(data) =>{
    return async (dispatch)=>{
        dispatch({
            type:"JOB_DETAILS",
            data:data
        })
    }
}
export const CompanyDetails=(data) =>{
    return async (dispatch)=>{
        dispatch({
            type:"COMPANY_DETAILS",
            data:data
        })
    }
}
export const LoginUser=(data) =>{
    return async (dispatch)=>{
        dispatch({
            type:"LOGIN_USER",
            data:data
        })
    }
}
export const LogOut=()=>{
    return async (dispatch)=>{
        dispatch({
            type:"LOGOUT_USER",
        })
    }
}

export const setLoginType=(data) =>{
    return async (dispatch)=>{
        dispatch({
            type:"LOGIN_TYPE",
            data:data
        })
    }
}