import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import image_path from "../../components/common/ImagePath";
import "./Home.scss";
function Home() {
  const {
    shape1,
    shape2,
    shape3,
    shape6,
    shape7,
    shape9,
    shape10,
    shape11,
    employers,
    workwithus,
    banner1geek,
    dynamicurl1,
    dynamicurl2,
    dynamicurl3,
    dynamicurl4,
  } = image_path;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const state = useSelector((s) => s.signup);
  const [dynamicurl, setDynamicUrl] = useState(dynamicurl1);
  const ShowCool = () => {
    setDynamicUrl(dynamicurl3);
  };
  const ShowGet = () => {
    setDynamicUrl(dynamicurl4);
  };
  const ShowProvide = () => {
    setDynamicUrl(dynamicurl2);
  };
const imgData= [
  {imgName: "opensea"},
  {imgName: "procare"},
  {imgName: "dataai"},
  {imgName: "moveworks"},
  {imgName: "gorillas"},
  {imgName: "aimi"},
  {imgName: "jokr"},
]
  return (
    <>
      <div className="main-banner marketing-home animated fadeIn">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="hero-content">
                    <h1>Connect your startup to the best talent</h1>
                    <p className="mostrarpc">
                      GeekSpotters is a specialized recruiting firm that is
                      transforming the process to hire the best talent for your
                      fast-growing tech startup. Through innovation and
                      experience, we are making hiring simpler and faster.
                    </p>
                    <p className="mostrarcel">
                      GeekSpotters is a specialized recruiting firm that is
                      transforming the process to hire the best talent for your
                      fast-growing tech startup. Through innovation and
                      experience, we are making hiring simpler and faster.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="marketing-image">
                    <img src={banner1geek} className="bannergeek" alt="" />
                    <img
                      src={banner1geek}
                      className="wow fadeInUp"
                      data-wow-delay="0.4s"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="geekalign">
                <Link to="/contactus" className="btn btn-primary btn-view">
                  Get in contact
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray shape-1"></div>
        <div className="shape1">
          <img src={shape1} alt="img" />
        </div>
        <div className="shape2">
          <img src={shape2} alt="img" />
        </div>
        <div className="shape3">
          <img src={shape3} alt="img" />
        </div>
        <div className="shape6">
          <img src={shape6} alt="img" />
        </div>
        <div className="shape9">
          <img src={shape9} alt="shape" />
        </div>
        <div className="bubble-animate">
          <div className="circle small square1"></div>
          <div className="circle small square2"></div>
          <div className="circle small square3"></div>
          <div className="circle small square4"></div>
          <div className="circle small square5"></div>
          <div className="circle medium square1"></div>
          <div className="circle medium square2"></div>
          <div className="circle medium square3"></div>
          <div className="circle medium square4"></div>
          <div className="circle medium square5"></div>
          <div className="circle large square1"></div>
          <div className="circle large square2"></div>
          <div className="circle large square3"></div>
          <div className="circle large square4"></div>
        </div>
      </div>

      <section className="team-area ptb-100 bg-gray" id="team_area" style={{paddingTop: 15}}>
        <div className="container features-inner-area">
          
          
          <div className="section-title mb-3">
            <h2>Teams that trust us</h2>
            <div className="bar"></div>
          </div>
          <div className="row h-100 align-items-center d-flex logo-container" 
            style={{ 
              gap: 32, 
              marginTop: 60, 
              overflowX: "scroll", 
              flexWrap: 
              "nowrap", 
              justifyContent:"flex-start" 
            }}
          >
            {imgData.map((item, index) => (
              <img
                src={require(`../../assets/iconSVG/${item.imgName}.svg`).default}
                alt="employees"
                key={index}
              />
            ))}
          </div>

          <div className="section-title" style={{marginTop: 75}}>
            <h2>What we do for employers</h2>
            <div className="bar"></div>
          </div>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="features-image">
                <img src={employers} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="features-inner-content whatwedo">
                <div className="features-item">
                  <h3>
                    Free Applicant Tracking System, or integrate with your ATS.
                  </h3>
                  <p>
                    We created the perfect ATS for startups to start their
                    hiring and never lose sight of where candidates are in the
                    process. If you already have an ATS, we can do an
                    integration and you will receive and manage our candidates
                    in your ATS.
                    <br />
                    <br />
                  </p>
                </div>
                <div className="features-item">
                  <h3>Experienced recruiters + AI matching</h3>
                  <p>
                    Recruiters cannot be replaced by AI because resumes are not
                    enough to know the true talent of candidates, but AI can
                    help speed up the process where possible. GeekSpotters has
                    built tools to match candidates to your jobs, and then our
                    recruiters will make sure that they are a perfect match.{" "}
                    <br />
                    <br />
                  </p>
                </div>
                <div className="features-item">
                  <h3>Flexible service & support</h3>
                  <p>
                    You can have your own dedicated talent specialist, or a more
                    cost-effective way to manage our candidates from either our
                    free ATS or your ATS if you already have one.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="blanco_w" id="textstyle">
          <Link to="/iamgeek" className="btn btn-primary  btn-view">
            learn more
          </Link>
        </div>
        <br />
        <br />
      </section>

      <section className="features-area marketing-features ptb-100">
        <div className="features-inner-area">
          <div className="container">
            <div className="section-title">
              <h2>What we do for geeks</h2>
              <div className="bar"></div>
            </div>
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="features-inner-content">
                  <div className="features-item" onMouseOver={ShowGet}>
                    <div className="icon">
                      <i className="icofont-bank"></i>
                    </div>
                    <h3>Get cash when you get hired</h3>
                    <p>
                      GeekSpotters will give a monetary bonus to candidates that
                      pass an interview, and accept a job through us.
                    </p>
                  </div>
                  <div className="features-item" onMouseOver={ShowCool}>
                    <div className="icon">
                      <i className="icofont-gift"></i>
                    </div>
                    <h3>Cool post interview perks</h3>
                    <p>
                      We love to treat our candidates. If you are selected for
                      an onsite interview, we will give you a gift to use after
                      your interview.
                    </p>
                  </div>
                  <div className="features-item" onMouseOver={ShowProvide}>
                    <div className="icon">
                      <i className="icofont-ui-call"></i>
                    </div>
                    <h3>Provide resume guidance and interview coaching</h3>
                    <p>
                      We will take a deep look at your resume and make sure
                      you're not missing any keywords that align with your
                      current skills. Interviews can be tough, our job is to
                      help you prepare for them.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="features-image">
                  <img src={dynamicurl} alt="" id="cambiodeimagen" />
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div id="textstyle">
            <Link to="/iamgeek" className="btn btn-primary btn-view">
              learn more
            </Link>
          </div>
        </div>
        <div className="shape7">
          <img src={shape7} alt="shape" />
        </div>
        <div className="shape3">
          <img src={shape3} alt="img" />
        </div>
        <div className="bg-gray shape-1"></div>
        <div className="shape6">
          <img src={shape6} alt="img" />
        </div>
        <div className="shape9">
          <img src={shape9} alt="shape" />
        </div>
        <div className="shape10">
          <img src={shape10} alt="shape" />
        </div>
        <div className="shape11 rotateme">
          <img src={shape11} alt="shape" />
        </div>
        <br />
      </section>

      <section className="feedback-area ptb-100 bg-gray">
        <div className="container">
          <div className="section-title">
            <h2>Why work with us, and what sets us apart?</h2>
            <div className="bar"></div>
            <p></p>
          </div>
          <div className="overview-box">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-6 col-md-6 overview-img">
                <img src={workwithus} alt="" />
              </div>
              <div className="col-lg-6 col-md-6 overview-content padding-text-p">
                <h3>
                  We know what works in each step of the recruiting process
                </h3>
                <p className="">
                  Securing the best candidates in today’s competitive market
                  requires a talented recruiting team, an efficient hiring
                  process, and a delightful candidate experience. GeekSpotters
                  recruiters have hired thousands of candidates for technology
                  startups, we offer a free yet powerful ATS that is simple to
                  use, and we are the only recruiting firm offering perks and
                  sign-on bonuses to our candidates.
                </p>
              </div>
            </div>
          </div>
          <div id="textstyle">
            <Link to="/contactus" className="btn btn-primary btn-view">
              Get in contact
            </Link>
          </div>
        </div>
        <svg
          className="svg-feedback-bottom"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path
            d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z"
            fill="#ffffff"
          />
        </svg>
      </section>
    </>
  );
}

export default Home;
