import React from "react";
import { PROCESSING } from "../../utils/constant";

export default function Button({ loading, onClick, variant, children, ...rest }) {
    let styles = ""
    if(!variant){
        styles = "bg-info";
    }else if(variant == "cancel"){
        styles = "bg-cancel";
    }

    let type ="";
    if(loading === undefined || loading === true){
      type = 'button';
    }else {
      type = 'submit';
    }

  return (
    <button
      type={type}
      className={`btn btn-freelance-two ${styles}`}
      onClick={onClick}
      {...rest}
    >
      {loading? PROCESSING : children}
    </button>
  );
}
