import React, { lazy, Suspense } from "react";
import {
  Redirect,
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/Store/Store";
import Error from "./pages/Error/Error";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { ToastProvider } from "react-toast-notifications";
import { ToastContainer } from "react-toastify";
import WebsiteLayout from "./components/Layout/WebsiteLayout";
import DashboardLayout from "./components/Layout/DashboardLayout";
import "./assets/css/preloader.scss";
import BrowseLayout from "./components/Layout/BrowseLayout";
import BonusPageLayout from "./components/Layout/BonusPageLayout";
import SpotterDetailLayout from "./components/Layout/SpotterDetailLayout";
import ResetPasswordLayout from "./components/Layout/ResetPasswordLayout";
import ReferLayout from "./components/Layout/ReferLayout";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "react-toastify/dist/ReactToastify.css";
import CompanyJobLayout from "./components/Layout/CompanyJobLayout";
import ConfirmEmailLayout from "./components/Layout/ConfirmEmailLayout";

export default function App() {
  React.useEffect(() => {
    const script = document.createElement("script");
    // script.src = "../../asset/js/custom.js";
    script.src = "/asset/js/custom.js";
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ToastProvider>
        <ToastContainer autoClose={2000} />
        <Provider store={store}>
          <Suspense fallback="">
            <Router>
              <Switch>
                <Route exact path="/linkedin" component={LinkedInPopUp} />
                <Route
                  path="/candidatereview"
                  component={lazy(() =>
                    import("./components/candidate/ViewCandidate")
                  )}
                />

                <PrivateRoute
                  path="/referrer/dashboard"
                  component={lazy(() => import("./pages/refer/ReferDashboard"))}
                />

                <Route path="/dashboard" component={DashboardLayout} />

                <Route
                  path="/registration"
                  component={lazy(() =>
                    import("./components/Layout/RegistrationLayout")
                  )}
                />

                <CompanyJobLayout
                  exact
                  path="/client/jobs/:companyName"
                  component={lazy(() =>
                    import("./pages/BrowserCompanyJob/BrowserCompanyJob")
                  )}
                />
                <CompanyJobLayout
                  exact
                  path="/client/jobs/:companyName/:id"
                  component={lazy(() =>
                    import(
                      "./pages/BrowserCompanyJob/BrowserCompanyJobDetail/BrowserCompanyJobDetail"
                    )
                  )}
                />
                <BrowseLayout
                  exact
                  path="/jobs"
                  component={lazy(() =>
                    import("./pages/BrowserJob/BrowserJob")
                  )}
                />
                <BrowseLayout
                  exact
                  path="/marketing/export-candidate/by-category"
                  component={lazy(() =>
                    import("./pages/MarketingExport/MarketingExport")
                  )}
                />
                <BrowseLayout
                  exact
                  path="/aimi"
                  component={lazy(() => import("./pages/Aimi/Aimi"))}
                />
                <BrowseLayout
                  path="/jobs/:id"
                  component={lazy(() =>
                    import("./pages/BrowserJob/ViewJob.js/ViewJob")
                  )}
                />

                <BonusPageLayout
                  path="/bonus"
                  component={lazy(() => import("./pages/Bonus/Bonus"))}
                />
                <SpotterDetailLayout
                  path="/spotterDetail/:id"
                  component={lazy(() =>
                    import("./pages/SpotterDetails/SpotterDetails")
                  )}
                />
                <ResetPasswordLayout
                  path="/resetpassword/:token"
                  component={lazy(() =>
                    import("./pages/ResetPassword/ResetPassword")
                  )}
                />
                <ReferLayout
                  path="/jobrefer/:id"
                  component={lazy(() =>
                    import("./pages/BrowserJob/JobReferAFriend/ReferAFriend")
                  )}
                />
                <ReferLayout
                  path="/refer/thankyou/:id"
                  component={lazy(() =>
                    import(
                      "./pages/BrowserJob/RefererThankyouComponent/RefererThankyou"
                    )
                  )}
                />

                <Route path="/" component={WebsiteLayout} />

                <Route path="*">
                  <Error />
                </Route>
              </Switch>
            </Router>
          </Suspense>
        </Provider>
      </ToastProvider>
    </LocalizationProvider>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};
