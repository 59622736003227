import React, { useEffect, useState ,useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import "./RegistrationHeader.scss";
// import '../../../assets/reg/plugins/plugins.css'
// import '../../assets/reg/plugins/css/plugins.scss';
// import '../../../assets/reg/css/style.css'
import { LogOut } from "../../Signup/Store/action";
const RegistrationHeader = () => {
  const container = useRef();
  const [showHeader, setShowHeader] = useState(false);
  const LoginType = useSelector((state) => state.signup);
  const { loginType } = LoginType;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClickOutside = (event) => {
    if (container.current && !container.current.contains(event.target)) {
      setShowHeader(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  },[handleClickOutside]);
const clangeShowHeader =()=>{
  setShowHeader(!showHeader)
}
  useEffect(() => {
    window.onresize = (e) => {
      if (window.innerWidth <= 767) {
        $("#navRes").removeClass("bootsnav");
        $("#navRes").addClass("navbar-expand-xl");
      } else {
        $("#navRes").removeClass("navbar-expand-xl");
        $("#navRes").addClass("bootsnav");
      }
    };
  });

  const goToMyAccount = () => {
    if (loginType === "candidate" || localStorage.loginType === "candidate") {
      history.push("/referrer/dashboard");
    }else if(loginType === "employer" || localStorage.loginType === "employer"){
      history.push("/dashboard/emp")
    }else if(loginType === "admin" || localStorage.loginType === "admin"){
      history.push("/dashboard/super")
    }else {
      history.push("/dashboard/spotter")
    }
  };

  const logOut = () => {
    dispatch(LogOut());
  };
  return (
    <>
      <nav
        className="navbar navbar-fixed navbar-light white on no-full bootsnav registration-header wrap-reg-hea"
        id="registrationStyles" ref={container}
      >
        <div className="container reg-header" id="displayStyle">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#navbar-menu"
          >
            <em className="fa fa-bars side-btn" onClick={clangeShowHeader}></em>
          </button>
          <div className="navbar-header nav-lgo">
            <a className="navbar-brand brand-log" style={{width:"205px" , padding:"0px 10px"}} href="/">
              <img
                src={require("../../../assets/Image/png/logogeek.png").default}
                className="logo logo-scrolled"
                alt="" 
                style={{width:"194px" ,padding:"5px 10px" ,height:"50.5px"}}
              />
            </a>
          </div>
          {(localStorage.token === undefined && showHeader) ? ( <div
            className="dropdoen-op"
            
           
          >
            
            
              <>
                <ul
                  className="nav navbar-nav navbar-right"
                
                >
                  <li>
                    <Link className="signin" to="/login">Login</Link>
                  </li>
                  <li className="left-br">
                    <Link to="/signup" className="signin">
                      sign up now
                    </Link>
                  </li>
                </ul>
              </>
              </div>
            ) : (
              <></>
            )}
            {(localStorage.token !== undefined && showHeader) ? (
              <div
              className="dropdoen-op"
              
             
            >
               
                <ul
                  className="nav navbar-nav navbar-right"
                  data-in="fadeInDown"
                  data-out="fadeOutUp"
                >
                  <li>
                    <Link to="#" className="signin" onClick={goToMyAccount}>
                      View my profile
                    </Link>
                  </li>
                  <li className="left-br">
                    <Link to="#" onClick={logOut} className="signin" style={{background:'#22b573'}}>
                      Log out
                    </Link>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        
      </nav>

      <div className="clearfix"></div>
    </>
  );
};

export default RegistrationHeader;
