import headerLogoImg from "../../assets/Image/png/logogeek.png";
import logogeek_2mobil_b from "../../assets/Image/png/logogeek_2mobil_b.png";
import logoImg from "../../assets/Image/png/logogeek_2mobil.png";
import logo_blanco from "../../assets/Image/png/logogeek_blanco.png";
import logogeek_spoilers from "../../assets/Image/png/logogeek_spoilers.png";
import become1 from "../../assets/Image/png/become1.png";
import become1_mobile from "../../assets/Image/png/become1_mobil.png";
import shape1 from "../../assets/Image/png/shape1.png";
import shape2 from "../../assets/Image/png/shape2.png";
import shape3 from "../../assets/Image/png/shape3.png";
import shape4 from "../../assets/Image/png/shape4.png";
import shape6 from "../../assets/Image/png/shape6.png";
import shape7 from "../../assets/Image/png/shape7.png";
import shape9 from "../../assets/Image/svg/shape9.svg";
import shape10 from "../../assets/Image/svg/shape10.svg";
import shape11 from "../../assets/Image/svg/shape11.svg";
import geeklaptop1 from "../../assets/Image/png/geeklaptop1.png";
import siliconvalley_geek from "../../assets/Image/png/siliconvalley_geek.png";
import distantwork from "../../assets/Image/png/distantwork_siliconvalley_geek.png";
import Support_geek_1 from "../../assets/Image/png/Support_geek_1.png";
import flechas_1 from "../../assets/Image/png/flechas_1.png";
import dinner from "../../assets/Image/png/dinner_geek_1.png";
import hireImage from "../../assets/Image/png/hire_talent1.png";
import Benefitst from "../../assets/Image/png/Benefitstowork_1.png";
import employers from "../../assets/Image/png/employers.png";
import workwithus from "../../assets/Image/png/Why-work-with-us-and-what-sets-us-apart.png";
import banner1geek from "../../assets/Image/png/banner1geek.png";
import dynamicurl1 from "../../assets/Image/png/sillon.png";
import dynamicurl2 from "../../assets/Image/png/geek1.png";
import dynamicurl3 from "../../assets/Image/png/geek2.png";
import dynamicurl4 from "../../assets/Image/png/geek3.png";
import Coolafteronsite from "../../assets/Image/png/Cool-after-onsite-interview-gifts.png";
import yougetasign from "../../assets/Image/png/You-get-a-sign-on-bonus-from-us.png";
import geekImg1 from "../../assets/Image/png/geek_img1.png";
import geekImg3 from "../../assets/Image/png/geek_img3.png";
import geekImg2 from "../../assets/Image/png/geek_img2.png";
import imageek1 from "../../assets/Image/png/imageek1.png";
import drone from "../../assets/Image/png/drone_geek.png";
import signup_left from "../../assets/Image/png/signup_left.png";
import signup_right from "../../assets/Image/png/signup_right.png";
import signup_top from "../../assets/Image/png/signup_top.png";
import tech_lab from "../../assets/Image/png/techlab.png";

import mobile_app from "../../assets/Image/png/mobileapp.png";
import proto_type from "../../assets/Image/png/prototype.png";
import mvp from "../../assets/Image/png/mvp.png";
import software from "../../assets/Image/png/software.png";

import mobdev from "../../assets/Image/png/mobdev.png";
import protodev from "../../assets/Image/png/protodev.png";
import mvpdev from "../../assets/Image/png/mvpdev.png";
import softdev from "../../assets/Image/png/softdev.png";
import arrowUp from "../../assets/Image/png/arrowUp.png";

const image_path = {
  signup_left,
  signup_right,
  signup_top,
  Coolafteronsite,
  yougetasign,
  geekImg1,
  geekImg2,
  geekImg3,
  imageek1,
  drone,
  headerLogoImg,
  logogeek_2mobil_b,
  logoImg,
  logo_blanco,
  logogeek_spoilers,
  become1,
  become1_mobile,
  shape1,
  shape2,
  shape3,
  shape4,
  shape6,
  shape7,
  shape9,
  shape10,
  shape11,
  geeklaptop1,
  siliconvalley_geek,
  distantwork,
  Support_geek_1,
  flechas_1,
  dinner,
  hireImage,
  Benefitst,
  employers,
  workwithus,
  banner1geek,
  dynamicurl1,
  dynamicurl2,
  dynamicurl3,
  dynamicurl4,
  tech_lab,
  mobile_app,
  proto_type,
  mvp,
  software,
  mobdev,
  protodev,
  mvpdev,
  softdev,
  arrowUp
};

export default image_path;
