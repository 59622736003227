import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import "./ConfirmEmail.scss";
import * as service from "../../utils/service";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notify } from "../../utils/notification";
import ReCAPTCHA from "react-google-recaptcha";
import { FALSE } from "sass";

const ConfirmEmailEmployer = () => {
  const [mailConfirming, setMailConfirming] = useState(true);
  const [mailConfirmed, setMailConfirmed] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonStyle, setbuttonStyle] = useState(false);
  const [userData, setUserData] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(
    process.env.React_App_NODE_ENV === "test" ? "false" : ""
  );

  useEffect(() => {
    confirmEmailProfile();
  }, []);
  const { confirmationToken } = useParams();
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    confirmpassword: "",
    accountTerms: false,
  });
  const history = useHistory();
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Please Enter your password.")
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$",
        "Your password must be at least 6 characters with 1 uppercase letter, 1 lower case and 1 number."
      ),
    confirmpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password does not match."
    ),
    accountTerms: Yup.boolean().required("Term & Condition is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const bodyFormData = {
        password: values.password,
      };
      service
        .confirmEmployerEmail(userData._id, bodyFormData)
        .then((resp) => {
          setIsLoading(false);
          if (resp.status === 200) {
            notify("success", "Password created successfully");
            resetForm();
            setTimeout(() => {
              notify("success", "Please login with your new password");
              history.push("/login");
            }, 1000);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          notify("error", err.response.data.message);
        });
    },
  });
  const confirmEmailProfile = () => {
    service
      .confirmEmail(confirmationToken)
      .then((resp) => {
        if (resp.status) {
          setMailConfirmed(true);
          setInitialValues({
            email: resp.data.data.user.email,
            password: "",
            confirmpassword: "",
            accountTerms: false,
          });
          setUserData(resp.data.data.user);
          setMailConfirming(false);
        }
      })
      .catch((err) => {
        if (err.response.status == 400) {
          if (err.response.data.message == "Token is invalid or has expired") {
            setIsTokenInvalid(true);
            setDisplayMessage(err.response.data.message);
            notify("error", err.response.data.message);
          }
        }
      });
  };
  useEffect(() => {
    if (
      recaptchaValue !== "" &&
      formik.values.accountTerms === true &&
      formik.values.password !== "" &&
      formik.values.confirmpassword !== ""
    ) {
      setbuttonStyle(true);
    } else {
      setbuttonStyle(false);
    }
  }, [
    formik.values.email,
    formik.values.password,
    recaptchaValue,
    formik.values.accountTerms,
  ]);
  function onChange(value) {
    setRecaptchaValue(value);
  }

  return (
    <>
      <div className="wrapper confi" id="confirmEmail">
        <div className="clearfix"></div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="detail-desc section">
          <div className="container">
            <div className="ur-detail-wrap create-kit padd-bot-0">
              <div className="" id="widthstyle">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {mailConfirming && (
                    <img
                      src={
                        require("../../assets/Image/png/logogeekspotters_grandevertical.png")
                          .default
                      }
                      className="img-responsive"
                      alt=""
                      id="marginstyle"
                    />
                  )}
                  {mailConfirming && !isTokenInvalid ? (
                    <div className="text-center">
                      <p>Please wait your mail is verifying...</p>
                    </div>
                  ) : (
                    <p style={{ color: "red" }}>{displayMessage}</p>
                  )}
                </div>
                {!mailConfirming && mailConfirmed && (
                  <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="login-form">
                        <h3>Set Password</h3>
                        <form onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12 col-md-12">
                              <div className="form-group">
                                <input
                                  {...formik.getFieldProps("email")}
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder="Email*"
                                  readOnly
                                />
                                {formik.touched.password &&
                                  formik.errors.email && (
                                    <div class="help-block with-errors">
                                      <span
                                        id="colorstyles"
                                        style={{ color: "red" }}
                                      >
                                        {formik.errors.email}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 col-12 col-md-12">
                              <div className="form-group">
                                <input
                                  autoComplete="current-password"
                                  type="password"
                                  name="password"
                                  className="form-control"
                                  placeholder="Password*"
                                  onChange={formik.handleChange}
                                  value={formik.values.password}
                                />
                                {formik.touched.password &&
                                  formik.errors.password && (
                                    <div class="help-block with-errors">
                                      <span style={{ color: "red" }}>
                                        {formik.errors.password}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 col-12 col-md-12">
                              <div className="form-group">
                                <input
                                  {...formik.getFieldProps("confirmpassword")}
                                  type="password"
                                  name="confirmpassword"
                                  className="form-control"
                                  placeholder="Confirm Password*"
                                  onChange={formik.handleChange}
                                  value={formik.values.confirmpassword}
                                />
                                {formik.touched.confirmpassword &&
                                  formik.errors.confirmpassword && (
                                    <div class="help-block with-errors">
                                      <span style={{ color: "red" }}>
                                        {formik.errors.confirmpassword}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  width: "92%",
                                  margin: "auto",
                                  display: "flex",
                                  alignItems: "baseline",
                                  gap: "10px",
                                  marginTop:"10px"
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id="1"
                                  name="accountTerms"
                                  {...formik.getFieldProps("accountTerms")}
                                />
                                <div>
                                  By creating an account, you agree to our{" "}
                                  <Link to="/terms" target="_blank">
                                    Terms of Use
                                  </Link>{" "}
                                  and{" "}
                                  <Link to="/privacy" target="_blank">
                                    Privacy Policy
                                  </Link>
                                  .
                                </div>
                              </div>
                              {formik.touched.accountTerms &&
                                formik.errors.accountTerms && (
                                  <div className="help-block with-errors">
                                    <span id="colorStyle">
                                      {formik.errors.accountTerms}
                                    </span>
                                  </div>
                                )}
                            </div>

                            <div
                              className="col-lg-6 col-sm-12 col-md-12 col-12  text-center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {isMobile ? (
                                <ReCAPTCHA
                                  sitekey={
                                    process.env.React_App_Recaptcha_Sitekey
                                  }
                                  onChange={onChange}
                                  size="compact"
                                />
                              ) : (
                                <ReCAPTCHA
                                  key="normal-recaptcha"
                                  sitekey={
                                    process.env.React_App_Recaptcha_Sitekey
                                  }
                                  onChange={onChange}
                                  size="normal"
                                />
                              )}
                            </div>

                            <div
                              className="col-lg-12"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {isLoading ? (
                                <>
                                  <button
                                    type="button"
                                    id="submitButton"
                                    disabled={true}
                                    className="btn btn-primary"
                                  >
                                    Processing...
                                  </button>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {buttonStyle ? (
                                    <>
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Set Password
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        id="submitButtonDisabled"
                                        type="submit"
                                        disabled={true}
                                        className="btn btn-primary mr-2 disabled"
                                      >
                                        Set Password
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEmailEmployer;
